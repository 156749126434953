import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { PostoService } from '../../../../service/posto.service';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { RegiaoService } from '../../../../service/regiao.service';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { orgao } from '../../../../modal/regional/orgao';
import { servico } from '../../../../modal/regional/servico';
import { MotorConfig } from '../../../../modal/motorConfig';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ServicoService } from '../../../../service/servico.service';
import { OrgaoService } from '../../../../service/orgao.service';
import { MotorConfigService } from '../../../../service/motorConfiguracaoService';


@Component({
  selector: 'app-cadastro-adicionar-configuracao',
  standalone: true,
  imports: [MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule,
    NgxMaskDirective],
  templateUrl: './cadastro-adicionar-configuracao.component.html',
  styleUrl: './cadastro-adicionar-configuracao.component.scss',
  providers: [provideNgxMask({ /* opções de cfg */ })]
})
export class CadastroAdicionarConfiguracaoComponent {

  statusOptions = [
    { id: '1', descricao: 'Ativo' },
    { id: '2', descricao: 'Inativo' }
  ];
  data = [];
  public listaOrg: orgao[] = [];
  //novoItem: servico = new servico();
  public isEditMode: boolean = false;
  novoItem: MotorConfig = new MotorConfig();
  @ViewChild('mySelect') mySelect: any;
  @ViewChild('mySelectFiltro') mySelectFiltro: any;

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private servicoService: ServicoService,
    private motorConfigService:MotorConfigService, 
    private orgaoService: OrgaoService,
    public dialog: MatDialog) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }

  submitForm() {
    
    if (this.novoItem.nome == undefined  || this.novoItem.nome.trim() === "" || this.novoItem.status === undefined || this.novoItem.codigo == undefined) {
      alert("Digite todos os campos obrigatorios!")
      return;
    }



    // Se 'dataAlteracao' não estiver definida, atribui a data atual
    var dataDeHoje = new Date().getTime(); // Pega a data e hora atual em timestamp (long)

    // Convertendo a data de alteração para long (timestamp)
    if (dataDeHoje && this.novoItem.id!=null) {
      this.novoItem.dataAtualizacao = dataDeHoje;      
    }
    console.log("item : ",this.novoItem);
    this.motorConfigService.adicionarItem(this.novoItem)
    .subscribe(
        () => {
            // Mensagem de sucesso
            alert("Item salvo com sucesso!");
            this.novoItem = new MotorConfig();
            this.mySelect.nativeElement.value = '';
            this.router.navigate(['/listar-motores']);
        },
        error => {
            // Mensagem de erro específica
            console.error('Erro ao adicionar novo item:', error);
            
            // Supondo que a mensagem de erro do backend é enviada no campo 'erro'
            if (error.error && error.error.erro) {
                alert(error.error.erro); // Exibe a mensagem de erro do backend
            } else {
                alert("Ocorreu um erro ao salvar o item."); // Mensagem genérica
            }
        }
    );
      
   
        
  }

  Limpar()
  {
    
    this.novoItem.id = null;
    this.novoItem.nome = "";
    
    this.mySelect.nativeElement.value = '';
  }

  // Método para capturar a mudança na seleção
  onOptionChange(value: string) {
    console.log('Selecionando status:', value);

    // Encontrar o objeto status que corresponde ao id
    const selectedStatus = this.statusOptions.find(x => x.id === value)?.id;
   

    // Verifica se o status foi encontrado
    if (selectedStatus == "2") {
        // Inicializa this.novoItem.status se estiver undefined
      this.novoItem.status=false;              
    } else  if (selectedStatus == "1"){
      this.novoItem.status=true; 
    }
}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let idEditar = params['IdEditar'];
      if(idEditar!=undefined)
        this.isEditMode = true; 
        this.AbrirEditarItem(idEditar);
    });

    
  }

  AbrirEditarItem(id: string | null): void {

   
    if (id != undefined) {
      this.motorConfigService.editarItem(id?.toString()).subscribe(
        (response) => {
          console.log(response);
          let p = response["MotorConfig"];
          this.novoItem.id = p['id'];
          this.novoItem.nome = p['nome'];         
          this.novoItem.status = p['status'];  // Já vem como booleano (true ou false)
          this.novoItem.codigo = p['codigo'];
          this.novoItem.bloquearExecucaoMotor = p['bloquearExecucaoMotor'];
          this.novoItem.nomeExecucaoMotor = p['nomeExecucaoMotor'];
          this.novoItem.avisoExecucaoMotor = p['avisoExecucaoMotor'];
          

        // Atualizar o valor do select com base no status (1 para true, 2 para false)
        const statusValue = this.novoItem.status ? '1' : '2';
        this.mySelect.nativeElement.value = statusValue;  // Atualizar select
        },
        (error) => {
          console.error('Erro ao excluir o item:', error);
        }
      );
    }
  }





}

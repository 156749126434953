<div class="sidenav" [ngClass]="{'sidenav-collapsed' : collapsed, 'dark-theme': isDarkTheme}" >
  <div class="logo-container">
    <div (click)="toggleCollapsed()">
      <button class="logo">
        <span class="material-symbols-outlined">
          <i class="fas fa-stream"></i>
        </span>
        <!-- <i class="fa fa-bars" aria-hidden="true"></i> -->
      </button>
    </div>
    <div class="logo-text" *ngIf="collapsed">Sala de Situação</div>
    <button class="btn-close" *ngIf="collapsed" (click)="closeSidenav()">
      <i class="fas fa-times close-icon"></i>
    </button>
  </div>

  <ul class="sidenav-nav" [style.height]="calcHeight">
    <!--Aplicar @for(){} - Angular v.17 -->
    @for (item of navData; track item.id) {
    <li class="sidenav-nav-item">
      @if(item.tipo !== 'Pai')
      {
      <a class="sidenav-nav-link" [routerLink]="item.routeLink" [matTooltipDisabled]="collapsed"
        matTooltipPosition="right" [matTooltip]="item.label" routerLinkActive="active" style="width: 100%;"
        [routerLinkActiveOptions]="{exact: true}">

        @if (collapsed) {
        <span class="sidenav-link-icon"><i [classList]="item.icon" style="margin-left: 10px;"></i></span>
        <span class="sidenav-link-text">{{ item.label }}</span>
        }
        @else
        {
        <span class="sidenav-link-icon" style="margin: auto;"><i [classList]="item.icon"
            style="margin-left: 10px;"></i></span>
        }
      </a>
      }
      @else
      {
      @if (item.open) {
      <ng-container *ngIf="item.EhFilhoEspecial === true &&  this.urlAtual == '/' + item.SoAparecerEm">
        <div class=" sidenav-nav-link" style="width: 100%;" (click)="toggleItem(item.id)"
          [matTooltipDisabled]="collapsed" matTooltipPosition="right" [matTooltip]="'Fechar '+ item.label">
          <div>
            <i class="fas fa-chevron-down" style="--fa-secondary-opacity: 1;margin-left: 10px;"></i>
            @if (collapsed) {
            <span class="sidenav-link-text">{{ item.label }}</span>
            }
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="item.EhFilhoEspecial != true">
        <div class=" sidenav-nav-link" style="width: 100%;" (click)="toggleItem(item.id)"
          [matTooltipDisabled]="collapsed" matTooltipPosition="right" [matTooltip]="'Fechar '+ item.label">
          <div>
            <i class="fas fa-chevron-down" style="--fa-secondary-opacity: 1;margin-left: 10px;"></i>
            @if (collapsed) {
            <span class="sidenav-link-text">{{ item.label }}</span>
            }
          </div>
        </div>
      </ng-container>
      }
      @else
      {
      <ng-container *ngIf="item.EhFilhoEspecial === true &&  this.urlAtual == '/'+item.SoAparecerEm">
        <div class=" sidenav-nav-link" style="width: 100%;" [matTooltipDisabled]="collapsed" matTooltipPosition="right"
          [matTooltip]="'Abrir '+ item.label" (click)="closeSidenavEspecial();toggleItem(item.id)">
          <div>
            <i class="fas fa-chevron-right" style="--fa-secondary-opacity: 1; margin-left: 10px;"></i>
            @if (collapsed) {
            <span class="sidenav-link-text">{{ item.label }}</span>
            }
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="item.EhFilhoEspecial != true">
        <div class=" sidenav-nav-link" style="width: 100%;" [matTooltipDisabled]="collapsed" matTooltipPosition="right"
          [matTooltip]="'Abrir '+ item.label" (click)="toggleItem(item.id)">
          <div>
            <i class="fas fa-chevron-right" style="--fa-secondary-opacity: 1; margin-left: 10px;"></i>
            @if (collapsed) {
            <span class="sidenav-link-text">{{ item.label }}</span>
            }
          </div>
        </div>
      </ng-container>
      }
      }
      <ul *ngIf="item.tipo === 'Pai' && item.open">
        <ng-container
          *ngIf="item.EhFilhoEspecial === true && item.TipoFilhoEspecial =='FiltroRegiaoPosto' && this.urlAtual == '/'+item.SoAparecerEm">
          <div>
            <div>
              <label for="nome" style="color: white;" class="nomeFiltroPosto form-label">Filtro do Posto:</label>
            </div>
            <div class="search-wrapper" style="margin-bottom: 0px; padding-bottom: 0px;">
              <form>
                <input type="text" required title="" value="{{pesquisaPosto}}" class="search-box form-control"
                  (keyup)="onKeyUp($event)" />
                <button class="text-clear" type="reset" title="Clear" (click)="onKeyUp(null)"></button>
              </form>
            </div>
          </div>
          <ng-container *ngFor="let reg of listaRegiao">
            @if (reg.regiao != undefined) {
            <li class="sidenav-nav-item-filho" style="display: flex; justify-content: left;">

              @if (reg.open) {
              <div class="sidenav-nav-link-filho" style="width: 100%;" (click)="toggleItemRegiao(reg.regiao.id)"
                [matTooltipDisabled]="collapsed" matTooltipPosition="right"
                [matTooltip]="'Fechar '+ reg.regiao.descricao">
                <div>
                  <i class="fas fa-chevron-down" style="--fa-secondary-opacity: 1;margin-left: 10px;"></i>
                  @if (collapsed) {
                  <span class="sidenav-link-text">{{ reg.regiao.descricao }}</span>
                  }
                </div>
              </div>
              }
              @else
              {
              <div class="sidenav-nav-link-filho" style="width: 100%;" [matTooltipDisabled]="collapsed"
                matTooltipPosition="right" [matTooltip]="'Abrir '+ reg.regiao.descricao"
                (click)="toggleItemRegiao(reg.regiao.id)">
                <div>
                  <i class="fas fa-chevron-right" style="--fa-secondary-opacity: 1; margin-left: 10px;"></i>
                  @if (collapsed) {
                  <span class="sidenav-link-text">{{ reg.regiao.descricao }}</span>
                  }
                </div>
              </div>
              }

            </li>
            <ng-container *ngIf="reg.open === true && this.urlAtual == '/'+item.SoAparecerEm">
              <li class="sidenav-nav-item-filho" style="display: flex; justify-content: left;"
                *ngFor="let pos of reg.postos | slice:0:reg.mostrarTudo ? reg.postos.length : 10">
                <a class=" sidenav-nav-link-filho" (click)="enviarFiltroPosto(pos.codigoCentralizado, pos.descricao)"
                  [matTooltipDisabled]="collapsed" matTooltipPosition="right"
                  style="width: 100%;min-height: 1rem !important;" [matTooltip]="pos.descricao"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  @if (collapsed) {
                  <span class="sidenav-link-icon"><i class="fas fa-minus" style="margin-left: 10px;"></i></span>
                  <span class="sidenav-link-text">{{ pos.descricao }}</span>
                  }
                  @else
                  {
                  <span class="sidenav-link-icon" style="margin: auto;"><i class="fas fa-minus"></i></span>
                  }
                </a>
              </li>
              @if (collapsed) {
              <div style="display: flex; justify-content: end;align-items: center; background-color: #26323800;">
                <button style="background-color: #26323800 !important;color: white;"
                  *ngIf="!reg.mostrarTudo && reg.postos.length > 10" (click)="mostrarMais(reg.regiao.id)"><i
                    class="fas fa-plus" style="padding-right: 5px;"></i>Mais</button>
                <button style="background-color: #26323800 !important;color: white;"
                  *ngIf="reg.mostrarTudo && reg.postos.length > 10" (click)="mostrarMenos(reg.regiao.id)"><i
                    class="fas fa-minus" style="padding-right: 5px;"></i>Menos</button>
              </div>
              }
              @else
              {
              <div style="background-color: #26323800;">
                <button style="background-color: #26323800 !important;color: white;"
                  *ngIf="!reg.mostrarTudo && reg.postos.length > 10" (click)="mostrarMais(reg.regiao.id)"><i
                    class="fas fa-plus"></i></button>
                <button style="background-color: #26323800 !important;color: white;"
                  *ngIf="reg.mostrarTudo && reg.postos.length > 10" (click)="mostrarMenos(reg.regiao.id)"><i
                    class="fas fa-minus"></i></button>
              </div>
              }
            </ng-container>
            }
          </ng-container>
        </ng-container>

        <li class="sidenav-nav-item-filho" style="display: flex; justify-content: left;"
          *ngFor="let filho of filtrarFilhos(item.id)">
          <a class="sidenav-nav-link-filho" [matTooltipDisabled]="collapsed" matTooltipPosition="right"
            style="width: 100%;" [matTooltip]="filho.label" [routerLink]="filho.routeLink" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
            @if (collapsed) {
            <span class="sidenav-link-icon"><i [classList]="filho.icon" style="margin-left: 10px;"></i></span>
            <span class="sidenav-link-text">{{ filho.label }}</span>
            }
            @else
            {
            <span class="sidenav-link-icon" style="margin: auto;"><i [classList]="filho.icon"></i></span>
            }
          </a>
        </li>
      </ul>
    </li>
    }
  </ul>

  @if (collapsed) {
  <div style="display: flex; justify-content: space-evenly;">
    <button (click)="AbrirVersionamento()" class="btn btn-secondary" style="width: 90%;background-color: #263238;">
      <i class="fas fa-code-branch"></i>
      Versionamento
    </button>
  </div>
  @if (IsIndicadorTela()) {
  <div style="display: flex; justify-content: space-evenly; padding-top: 10px;">
    <button (click)="openDialogAjuda()" class="btn btn-secondary" style="width: 90%;background-color: #263238;">
      <i class="fas fa-info-circle"></i>
      Ajuda
    </button>
  </div>
  }
  }
  @else
  {
  <div style="display: flex; justify-content: space-evenly;">
    <button (click)="AbrirVersionamento()" class="btn btn-secondary" style="width: 50%; background-color: #263238;">
      <i class="fas fa-code-branch"></i>
    </button>

  </div>
  @if (IsIndicadorTela()) {
  <div style="display: flex; justify-content: space-evenly; padding-top: 10px;">
    <button (click)="openDialogAjuda()" class="btn btn-secondary" style="width: 50%; background-color: #263238;">
      <i class="fas fa-info-circle"></i>
    </button>
  </div>
  }
  }

</div>





<!-- <div class="sidenav" [ngClass]=" collapsed ? 'sidenav-collapsed': '' ">

  <div class="logo-container" (click)="toggleCollapse()">

    <button class="logo" >A
       <img src="assets/NovoLogoSalaSituacao.png" width="200" height="50"  >
    </button>

    <div class="logo-text" @fadeInOut *ngIf="collapsed" (click)="closeSidenav()" >Sala de Situação</div>

    <button class="btn-close" @rotate *ngIf="collapsed" (click)="closeSidenav()">
      <i class="fas fa-times close-icon"></i>
    </button>

  </div>

  <ul class="sidenav-nav">
    <li class="sidenav-nav-item" *ngFor="let data of navData">
      <a class="sidenav-nav-link" [routerLink]="[data.routeLink]" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">
        <i class="sidenav-link-icon" [class]="data.icon"></i>
        <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
          {{data.label}}
        </span>
      </a>
    </li>
  </ul>

</div> -->
<section>
  <section style="height: auto;">
      <nav>
          <ol class="cd-breadcrumb custom-separator">
              <table>
                  <tbody>
                      <tr>
                          <td class="nomeTela">
                              {{NomeTela}}
                          </td>
                          <td>
                            <div *ngIf="atualizando" class="loader loader--style1" title="0">
                              <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                               width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
                              <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                                s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                                c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
                              <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                                C22.32,8.481,24.301,9.057,26.013,10.047z">
                                <animateTransform attributeType="xml"
                                  attributeName="transform"
                                  type="rotate"
                                  from="0 20 20"
                                  to="360 20 20"
                                  dur="0.5s"
                                  repeatCount="indefinite"/>
                                </path>
                              </svg>
                            </div>
                          </td>
                          <td style="position: absolute; right: 50px;">
                            <button type="button" class="cards-layout-CidEspera" (click)="MudarVisualizacaoTela('TABELA')">
                              <svg style="margin-right: 13px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M21 9.5H3M21 4.5H3M21 14.5H3M21 19.5H3" />
                              </svg>
                            </button>
                            <button type="button" (click)="MudarVisualizacaoTela('CARDS')">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M3 3h18v18H3zM21 9H3M21 15H3M12 3v18" />
                              </svg>
                            </button>
                          </td>
                      </tr>
                      <tr>
                          <td>
                              <li *ngFor="let bc of listabreadcrumb">
                                  @if (bc.url != "") {
                                    <button (click)="navigateWithStateBC(bc.url)">{{bc.label}}</button>
                                  }
                                  @else {
                                  <em>{{bc.label}}</em>
                                  }
                              </li>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </ol>
      </nav>
  </section>
@if (SemDados == true) {
  <h4 class="class-h4">Não exite dados para serem mostrados</h4>
}
@else if (TipoVisualizacaoTela == "CARDS") {
<section class="sectionInd">
  <div class="cards-containers" [ngStyle]="{'display': DisplayTela}">
    <div *ngFor="let mesa of listaMesa">
      <mat-card style="margin: 10px;  font-weight: bold;"
        [ngStyle]="{'background-color':'#dfedf9', 'border': '2px solid #2a505f'}">

        <div class="title-card" [ngStyle]="{'background-color': 'rgb(101 185 207)'}">
          <ng-container *ngFor="let cg of mesa.listaComponentesGraficos" [ngTemplateOutlet]="RenderComponenteGrafico"
            [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:mesa.valor.nome}"></ng-container>
          @if(mesa.valor.ehLink)
          {
          <button (click)="navigateWithState(mesa.valor.linkRedirect,mesa.valor.linkVariaveis)" style="background: #ffffff00;">
            <p style="font-weight: bold;">{{ mesa.valor.valorFormatado }}</p>
          </button>
          }
          @else
          {
          <p style="font-weight: bold !important;">{{ mesa.valor.valorFormatado }}</p>
          }
          <ng-container *ngFor="let cg of mesa.listaComponentesGraficos" [ngTemplateOutlet]="RenderComponenteGrafico" style="font-weight: bold;"
            [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:mesa.valor.nome}"></ng-container>
        </div>
        <ng-container *ngIf="mesa.valores == undefined || mesa.valores.length ==0">
          <h4 class="class-h4">Não exite dados para serem mostrados</h4>
        </ng-container>
        <sub-indicador-especial-card-vertical [valores]="mesa.valores" [orientacaoLoop]="mesa.valor.orientacaoLoop"
          [listaComponentesGraficos]="mesa.listaComponentesGraficos" [listabreadcrumb]="listabreadcrumb" [IdTela]="IdTela" [CcPosto]="CcPosto" [CcOrgao]="CcOrgao"></sub-indicador-especial-card-vertical>
      </mat-card>
    </div>
  </div>
</section>
}
@else if (TipoVisualizacaoTela == "TABELA") {
<div class="style-tabela">
  
  <div style="display: flex; justify-content: end;">
    <button type="button" (click)="extrairTabelaParaPDF()" class="btn btn-secondary" style="margin-right: 10px;">
      Exportar PDF<i class="fas fa-file-pdf fa-2xl" style="margin-left: 10px;"></i>
    </button>
    <button (click)="exportTableToExcel()" type="button" class="btn btn-secondary" style="margin-right: 10px;">
      Exportar Excel
      <i class="fas fa-file-excel fa-2xl" style="margin-left: 10px;"></i>
    </button>
  </div>
  
  <table border="0" cellpadding="0"  id="tableDados" cellspacing="0" width="75%" style="margin-left: 6%;margin-top: 1%;">
    <tbody>
      <tr>
        <td class="top center" *ngIf="!SemOPrimeiroCampo"><i class="icofont-users-alt-4"></i></td>
        <td class="top center" *ngFor="let coluna of cabecalho" style="font-weight: bold;"><i [classList]="coluna.icone"
            style="color: #4b4a4a; transform: scale(1.3,1.3); margin-right: 10px;"></i>{{ coluna.nome }}</td>
      </tr>
    </tbody>
    <tbody *ngFor="let indicador of listaMesa">
      <tr *ngIf="indicador.valores != undefined && indicador.valores.length !=0">
        <ng-container *ngIf="ListaDentroLista && !indicador.valor.naoMostraCampo ">

          <td [attr.rowspan]="indicador.valores.length">
            <table class="tableEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
              <tbody class="tbodyEspecial">
                <tr class="trEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
                  <td class="tdEspecial1" style="border: 0px;background-color: #f0f8ff00 !important;">
                    <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                      [ngTemplateOutlet]="RenderComponenteGrafico"
                      [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:indicador.valor.nome}"></ng-container>
                  </td>
                  <td class="tdEspecial2" style="border: 0px;background-color: #f0f8ff00 !important;">
                    @if(indicador.valor.ehLink)
                    {
                    <button (click)="navigateWithState(indicador.valor.linkRedirect,indicador.valor.linkVariaveis)" style="background: #ffffff00;">
                      <p style="font-weight: bold;">{{ indicador.valor.valorFormatado }}</p>
                    </button>
                    }
                    @else
                    {
                    <p style="font-weight: bold;">{{ indicador.valor.valorFormatado }}</p>
                    }
                  </td>
                  <td style="border: 0px;background-color: #f0f8ff00 !important;">
                    <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                      [ngTemplateOutlet]="RenderComponenteGrafico"
                      [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:indicador.valor.nome}"></ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <ng-container>

            <td *ngIf="indicador.valores[0].naoMostraCampo!=true">
              <table class="tableEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
                <tbody class="tbodyEspecial">
                  <tr class="trEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
                    <td class="tdEspecial1" style="border: 0px;background-color: #f0f8ff00 !important;">
                      <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                        [ngTemplateOutlet]="RenderComponenteGrafico"
                        [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:indicador.valores[0].nome}"></ng-container>
                    </td>
                    <td class="tdEspecial2" style="border: 0px;background-color: #f0f8ff00 !important;">
                      @if(indicador.valores[0].ehLink)
                      {
                      <button (click)="navigateWithState(indicador.valor.linkRedirect,indicador.valor.linkVariaveis)" style="background: #ffffff00;">
                        <p style="font-weight: bold;">{{ indicador.valores[0].valorFormatado }}</p>
                      </button>
                      }
                      @else
                      {
                      <p style="font-weight: bold;">{{ indicador.valores[0].valorFormatado }}</p>
                      }
                    </td>
                    <td style="border: 0px;background-color: #f0f8ff00 !important;">
                      <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                        [ngTemplateOutlet]="RenderComponenteGrafico"
                        [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:indicador.valores[0].nome}"></ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <ng-container *ngIf="indicador.valores[0].valores && indicador.valores[0].valores.length > 0">
              <ng-container [ngTemplateOutlet]="renderRows"
                [ngTemplateOutletContext]="{items:indicador.valores[0].valores, listaComponentesGraficos: indicador.listaComponentesGraficos}"></ng-container>
            </ng-container>
          </ng-container>

        </ng-container>
      </tr>
      <tr *ngIf="indicador.valores == undefined || indicador.valores.length ==0">
        <td>
          <table class="tableEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
            <tbody class="tbodyEspecial">
              <tr class="trEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
                <td class="tdEspecial1" style="border: 0px;background-color: #f0f8ff00 !important;">
                  <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                    [ngTemplateOutlet]="RenderComponenteGrafico"
                    [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:indicador.valor.nome}"></ng-container>
                </td>
                <td class="tdEspecial2" style="border: 0px;background-color: #f0f8ff00 !important;">
                  @if(indicador.valor.ehLink)
                  {
                  <button (click)="navigateWithState(indicador.valor.linkRedirect,indicador.valor.linkVariaveis)" style="background: #ffffff00;">
                    <p style="font-weight: bold;">{{ indicador.valor.valorFormatado }}</p>
                  </button>
                  }
                  @else
                  {
                  <p style="font-weight: bold;">{{ indicador.valor.valorFormatado }}</p>
                  }
                </td>
                <td style="border: 0px;background-color: #f0f8ff00 !important;">
                  <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                    [ngTemplateOutlet]="RenderComponenteGrafico"
                    [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:indicador.valor.nome}"></ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <ng-container *ngIf="!ListaDentroLista">
        <tr *ngIf="indicador.valores != undefined && indicador.valores.length !=0">
          <td *ngIf="!indicador.valor.naoMostraCampo ">
            <table class="tableEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
              <tbody class="tbodyEspecial">
                <tr class="trEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
                  <td class="tdEspecial1" style="border: 0px;background-color: #f0f8ff00 !important;">
                    <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                      [ngTemplateOutlet]="RenderComponenteGrafico"
                      [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:indicador.valor.nome}"></ng-container>
                  </td>
                  <td class="tdEspecial2" style="border: 0px;background-color: #f0f8ff00 !important;">
                    @if(indicador.valor.ehLink)
                    {
                    <button (click)="navigateWithState(indicador.valor.linkRedirect,indicador.valor.linkVariaveis)" style="background: #ffffff00;">
                      <p style="font-weight: bold;">{{ indicador.valor.valorFormatado }}</p>
                    </button>
                    }
                    @else
                    {
                    <p style="font-weight: bold;">{{ indicador.valor.valorFormatado }}</p>
                    }
                  </td>
                  <td style="border: 0px;background-color: #f0f8ff00 !important;">
                    <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                      [ngTemplateOutlet]="RenderComponenteGrafico"
                      [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:indicador.valor.nome}"></ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <ng-container *ngFor="let valor of indicador.valores; let i= index;">
            <ng-container *ngIf="!ListaDentroLista">
              <td *ngIf="valor.naoMostraCampo!=true && !valor.ehCampoCabecalho">
                <table class="tableEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
                  <tbody class="tbodyEspecial">
                    <tr class="trEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
                      <td class="tdEspecial1" style="border: 0px;background-color: #f0f8ff00 !important;">
                        <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                          [ngTemplateOutlet]="RenderComponenteGrafico"
                          [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:valor.nome}"></ng-container>
                      </td>
                      <td class="tdEspecial2" style="border: 0px;background-color: #f0f8ff00 !important;">
                        @if(valor.ehLink)
                        {
                        <button (click)="navigateWithState(valor.linkRedirect,valor.linkVariaveis)" style="background: #ffffff00;">
                          <p style="font-weight: bold;">{{ valor.valorFormatado }}</p>
                        </button>
                        }
                        @else
                        {
                        <p style="font-weight: bold;">{{ valor.valorFormatado }}</p>
                        }
                      </td>
                      <td style="border: 0px;background-color: #f0f8ff00 !important;">
                        <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                          [ngTemplateOutlet]="RenderComponenteGrafico"
                          [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:valor.nome}"></ng-container>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <ng-container *ngIf="valor.valores && valor.valores.length > 0">
                <ng-container [ngTemplateOutlet]="renderRows"
                  [ngTemplateOutletContext]="{items:valor.valores, listaComponentesGraficos: indicador.listaComponentesGraficos}"></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </tr>
      </ng-container>

      <ng-container *ngIf="ListaDentroLista">
        <ng-container *ngIf="indicador.valores != undefined && indicador.valores.length !=0">
          <ng-container *ngFor="let valor of indicador.valores; let i= index;">
            <tr *ngIf="i!=0">
              <td *ngIf="valor.naoMostraCampo!=true && !valor.ehCampoCabecalho">
                <table class="tableEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
                  <tbody class="tbodyEspecial">
                    <tr class="trEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
                      <td class="tdEspecial1" style="border: 0px;background-color: #f0f8ff00 !important;">
                        <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                          [ngTemplateOutlet]="RenderComponenteGrafico"
                          [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:valor.nome}"></ng-container>
                      </td>
                      <td class="tdEspecial2" style="border: 0px;background-color: #f0f8ff00 !important;">
                        @if(valor.ehLink)
                        {
                        <button (click)="navigateWithState(valor.linkRedirect,valor.linkVariaveis)" style="background: #ffffff00;">
                          <p style="font-weight: bold;">{{ valor.valorFormatado }}</p>
                        </button>
                        }
                        @else
                        {
                        <p style="font-weight: bold;">{{ valor.valorFormatado }}</p>
                        }
                      </td>
                      <td style="border: 0px;background-color: #f0f8ff00 !important;">
                        <ng-container *ngFor="let cg of indicador.listaComponentesGraficos"
                          [ngTemplateOutlet]="RenderComponenteGrafico"
                          [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:valor.nome}"></ng-container>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>

              <ng-container *ngIf="valor.valores && valor.valores.length > 0">
                <ng-container [ngTemplateOutlet]="renderRows"
                  [ngTemplateOutletContext]="{items:valor.valores, listaComponentesGraficos: indicador.listaComponentesGraficos}"></ng-container>
              </ng-container>

            </tr>
          </ng-container>
        </ng-container>
      </ng-container>

    </tbody>
  </table>
</div>
}

<ng-template #renderRows let-items="items" let-listaComponentesGraficos="listaComponentesGraficos">
  <ng-container *ngFor="let item of items">
    <td *ngIf="item.naoMostraCampo!=true  && !item.ehCampoCabecalho">
      <table class="tableEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
        <tbody class="tbodyEspecial">
          <tr class="trEspecial" style="border: 0px;background-color: #f0f8ff00 !important;">
            <td class="tdEspecial1" style="border: 0px;background-color: #f0f8ff00 !important;">
              <ng-container *ngFor="let cg of listaComponentesGraficos" [ngTemplateOutlet]="RenderComponenteGrafico"
                [ngTemplateOutletContext]="{valor:cg, possicao:'ESQUERDA', campo:item.nome}"></ng-container>
            </td>
            <td class="tdEspecial2" style="border: 0px;background-color: #f0f8ff00 !important;">
              @if(item.ehLink)
              {
              <button (click)="navigateWithState(item.linkRedirect,item.linkVariaveis)" style="background: #ffffff00;">
                <p style="font-weight: bold;">{{ item.valorFormatado }}</p>
              </button>
              }
              @else
              {
              <p style="font-weight: bold;">{{ item.valorFormatado }}</p>
              }

            </td>
            <td style="border: 0px;background-color: #f0f8ff00 !important;">
              <ng-container *ngFor="let cg of listaComponentesGraficos" [ngTemplateOutlet]="RenderComponenteGrafico"
                [ngTemplateOutletContext]="{valor:cg, possicao:'DIREITA', campo:item.nome}"></ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
    <ng-container *ngIf="item.valores && item.valores.length > 0">
      <ng-container [ngTemplateOutlet]="renderRows"
        [ngTemplateOutletContext]="{items:item.valores, listaComponentesGraficos: listaComponentesGraficos}"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>


<ng-template #RenderComponenteGrafico let-valor="valor" let-possicao="possicao" let-campo="campo">
  <ng-container *ngIf="valor.componenteGraficoPossicao=='{'+campo+';'+possicao+'}'">
    <app-display-componente-grafico [valor]="valor"></app-display-componente-grafico>
  </ng-container>
</ng-template>



<!-- <app-header></app-header> -->


<div [ngClass]="themeClass" style="min-height: 100%;" *ngIf="!router.url.includes('iniciar') && !router.url.includes('loading')">
  <div>
    <!--  class="body"  [ngClass]="getBodyClass()"-->

    <app-header (themeToggle)="toggleTheme($event)"></app-header>
    <router-outlet></router-outlet>


</div>
</div>

<div class="body-semmenu" *ngIf="router.url.includes('iniciar') || router.url.includes('loading')">
    <!--  class="body"  [ngClass]="getBodyClass()"-->
    <app-header></app-header>
    <router-outlet></router-outlet>
</div>
<!--

  ENTENDI

  - pegar a classe body como exemplo e a sua função getBodyClass()
    junto com a sua classe SCSS
  - replicar a mesma configuração no compponente header.ts/header.html/header.scss

  - Ajustar o icone do semaforo
  - Ajustar css da tela de postos por região

-->

import { FormsModule } from '@angular/forms';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { AuthService } from '../../../../service/auth.service';
import { MotorConfig } from '../../../../modal/motorConfig';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { MotorConfigService } from '../../../../service/motorConfiguracaoService';
import { OrgaoService } from '../../../../service/orgao.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { MotorService } from '../../../../service/motor.service';

@Component({
  selector: 'app-cadastro-listagem-configuracao',
  standalone: true,
  imports: [MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule],
  templateUrl: './cadastro-listagem-configuracao.component.html',
  styleUrl: './cadastro-listagem-configuracao.component.scss'
})
export class CadastroListagemConfiguracaoComponent {
  data = [];
  public listarMotores: MotorConfig[] = [];
  novoItem: MotorConfig = new MotorConfig();
  pesquisaNome: string = "";
  pesquisaStatus: string = "";


  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private motorExexService: MotorService,
    private motorService: MotorConfigService,
    public dialog: MatDialog,
    private authService: AuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }
  ngOnInit() {
    console.log("Iniciar a pesquisa")
    this.Pesquisa();
  }

  MudaFiltro() {
    this.pageIndex = 0;
    this.Pesquisa()
  }

  Pesquisa() {
    this.listarMotores = [];
    //console.log(this.pesquisaCodCentralizado)
    this.motorService.getMotorPaginacao(this.pageIndex, this.pageSize, this.pesquisaNome, this.pesquisaStatus).pipe(
    ).subscribe((response) => {

      this.data = response["MotorConfig"];
      this.totalPages = response["totalPages"];
      let i = 0;
      console.log(response);
      this.data.forEach(element => {
        i++;
        // Verifica se 'dataAlteracao' existe, caso contrário define um valor padrão    
        // Converte o timestamp para um objeto Date
        const dataAlteracao = element['dataAtualizacao'] ? new Date(element['dataAtualizacao']) : null;
        const dataUltimaExecucao = element['dataUltimaExecucao'] ? new Date(element['dataUltimaExecucao']) : null;
        const status = element['status'] === true ? "Ativo" : "Inativo";
        let p = new MotorConfig(element['id'], element['nome'], element['status'], dataAlteracao ? dataAlteracao.getTime() : null, element['codigo'], dataUltimaExecucao ? dataUltimaExecucao.getTime() : null, element['bloquearExecucaoMotor'], element['nomeExecucaoMotor'], element['avisoExecucaoMotor']);
        this.listarMotores.push(p)
      });
    });
  }

  pageEvent: PageEvent | undefined;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalPages = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.Pesquisa();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  excluirItem(id: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Confirmação', message: 'Tem certeza que deseja excluir?  favor verificar se o motor está em utilização' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (id != undefined) {
          this.motorService.deleteItem(id?.toString()).subscribe(
            (response) => {
              console.log('Item excluído com sucesso!');
              this.Pesquisa();
            },
            (error) => {
              console.error('Erro ao excluir o item:', error);
            }
          );
        }
      }
    });

  }

  ExecutarItem(nomeExecucao: string | null, avisoExecucao: string | null): void {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { title: 'Confirmação', message: '' + avisoExecucao }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log("RES", result, nomeExecucao, avisoExecucao);
        if (result) {
          if (nomeExecucao != null) {
            this.motorExexService.getExecucao(nomeExecucao).subscribe();
          }
        }
      });
  }

  validarRole() {
    let role = this.authService.role();
    if (role == 'superuser') {
      return true;
    }
    return false;
  }
}

<section>
    <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            <td class="nomeTela">
                                Listagem do Status do Posto pela Agenda
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>
    <main>
        <section class="pesq-section">
            <form class="form-class" data-toggle="collapse" (ngSubmit)="MudaFiltro()">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaCCentralizado" class="form-label">Código Centralizado:</label>
                                    <input type="text" class="form-control" placeholder="Codigo Centralizado"
                                        id="pesquisaCCentralizado" name="pesquisaCCentralizado"
                                        [(ngModel)]="pesquisaCCentralizado" required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaDesc" class="form-label">Nome do Posto:</label>
                                    <input type="text" class="form-control" placeholder="Nome do Posto"
                                        id="pesquisaDesc" name="pesquisaDesc" [(ngModel)]="pesquisaDesc" required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="regiao" class="form-label">Status:</label>
                                    <select #mySelectFiltroStatus name="select" aria-placeholder="Regiao"
                                        class="form-select"
                                        (change)="onOptionChangeFiltroStatus(mySelectFiltroStatus.value)">
                                        <option value="0"></option>
                                        <option value="1">Aberto</option>
                                        <option value="2">Fechado</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="div-button mb-3 p-3" style="margin-top: 47px;">
                                    <button id="class-button" type="submit" class="btn btn-primary" (click)="Pesquisa()">Pesquisar</button>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </form>

        </section>
        <!--Tabela -->
        <section class="main-section-table">
            <!-- <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
                [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex"
                aria-label="Select page">
            </mat-paginator> -->
            <table class="table">
                <!--toJsonBody-->
                <thead>

                    <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col" style="cursor: pointer">Nome do Posto</th>
                        <th scope="col" style="cursor: pointer">Codigo Centralizado</th>
                        <th scope="col" style="cursor: pointer">Status</th>
                        <th scope="col" style="cursor: pointer">Numero de Vagas</th>
                        <th scope="col" style="cursor: pointer">Data Atualização</th>
                        <!--<th scope="col">Serviços do posto</th>
            <th scope="col">Órgãos do posto</th>
                        @if(validarRoleEditar() == true){
                        <th scope="col">Funções</th>
                        }-->
                    </tr>
                </thead>
                <tbody>
                    @for (p of data; track $index) {
                    <tr>
                        <!-- <td style="font-weight: bold;">{{$index+1}}</td> -->
                        <td style="font-weight: bold;">{{p["nomePosto"]}}</td>
                        <td>{{p["codigoCentralizado"]}}</td>
                        @if(p["aberto"] == undefined || p["aberto"] == false){
                        <td>Fechado</td>
                        }
                        @if(p["aberto"] == true)
                        {
                        <td>Aberto</td>
                        }
                        <td>{{p["qtdVagas"]}}</td>
                        <td>{{dateTimeFormatPipe.transform(p["dataAtualizacao"])}}</td>
                    </tr>
                    }
                </tbody>
            </table>

            <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
                [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex"
                aria-label="Select page">
            </mat-paginator>

        </section>


    </main>
</section>

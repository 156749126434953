import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicadoresService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.loadingSubject.asObservable();
  CarregaVagas = false;
  CarregaInd = false;

  show() {
    this.loadingSubject.next(true);
    this.CarregaVagas = false;
    this.CarregaInd = false;
  }

  hide(TipoCarrega: string) {
    if (TipoCarrega == "CarregaVagas") {
      this.CarregaVagas = true;
    }
    else if (TipoCarrega == "CarregaInd") {
      this.CarregaInd = true;
    }
    if (this.CarregaVagas == true && this.CarregaInd == true)
      this.loadingSubject.next(false);
  }

  hidePosto() {
      this.loadingSubject.next(false);
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { posto } from '../modal/regional/posto';

@Injectable({
  providedIn: 'root',
})
export class StatusPostoAgendaService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL;
  private map = "ss.status_posto_agenda";
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  getPaginacao(pageNumber: number, pageSize: number,filtroNome: string,filtroCcPosto: string,filtroStatus: string): Observable<any> { 
    return this.http.get(this.apiUrl+this.map+'/findpaginacao?pageNumber='+pageNumber+'&pageSize='+pageSize+'&filtroNome='+filtroNome+'&filtroStatus='+filtroStatus+"&filtroCcPosto="+filtroCcPosto, {withCredentials: true});
  }
}

<section>
    <section style="height: auto;">
      <nav>
          <ol class="cd-breadcrumb custom-separator">
              <table>
                  <tbody>
                      <tr>
                          <td class="nomeTela">
                              Cadastro de motor 
                          </td>
                      </tr>
                  </tbody>
              </table>
          </ol>
      </nav>
  </section>
    <main>
      <section class="main-section">
        <form class="form-class" data-toggle="collapse">
          <input type="text" class="form-control" id="id" name="id" [(ngModel)]="novoItem.id" required
            style="display: none;">

            <div class="form-div p-3">
              <label for="codigo" class="form-label">Codigo</label>
              <input type="number" class="form-control" id="codigo" name="codigo" [(ngModel)]="novoItem.codigo" required min="1" [disabled]="isEditMode">
            </div>
                  
          <div class="form-div p-3">
            <label for="descricao" class="form-label">Nome do motor</label>
            <input type="text" class="form-control" id="nomeMotor" name="nomeMotor" [(ngModel)]="novoItem.nome"
              required>
            <!-- <div id="nomePosto" class="form-text">Insira o nome do posto.</div> -->
          </div>
          <div class="form-div p-3">
            <label for="status" class="form-label">Status</label>
            <select #mySelect name="status" class="form-select" (change)="onOptionChange(mySelect.value)">
              <option value="">Escolha o Status</option>
              <option *ngFor="let status of statusOptions" [value]="status.id">{{status.descricao}}</option>
            </select>
          </div>
          <div class="form-div p-3" style=" padding-left: 56px !important;  padding-bottom: 2.5em; display: flex;">
            <div class="form-div form-check form-switch">
              <input class="form-check-input" type="checkbox" [(ngModel)]="novoItem.bloquearExecucaoMotor"
                name="bloquearExecucaoMotor" id="bloquearExecucaoMotor">
              <label class="form-check-label" for="flexCheckDefault">
                Desabilitar Execução manual do Motor?
              </label>
            </div>
          </div>
          <div class="form-div p-3">
            <label for="descricao" class="form-label">Nome da Api do motor</label>
            <input type="text" class="form-control" id="nomeExecucaoMotor" name="nomeExecucaoMotor" [(ngModel)]="novoItem.nomeExecucaoMotor"
              required>
            <!-- <div id="nomePosto" class="form-text">Insira o nome do posto.</div> -->
          </div>
          <div class="form-div p-3">
            <label for="descricao" class="form-label">Aviso para execução do motor</label>
            <input type="text" class="form-control" id="avisoExecucaoMotor" name="avisoExecucaoMotor" [(ngModel)]="novoItem.avisoExecucaoMotor"
              required>
            <!-- <div id="nomePosto" class="form-text">Insira o nome do posto.</div> -->
          </div>
          <div class="div-button mb-3 p-3">
            <a style="margin-left: 80%; margin-right: 10px;" [routerLink]="['/listar-motores']"><button id="class-button" class="btn btn-secondary">Voltar</button></a>
            <button id="class-button" style="margin-right: 10px;" (click)="Limpar()" class="btn btn-secondary">Limpar</button>
            <button id="class-button" (click)="submitForm()" class="btn btn-primary">Salvar</button>
          </div>
        </form>
      </section>
    </main>
  </section>
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { orgao } from '../../../../modal/regional/orgao';
import { OrgaoService } from '../../../../service/orgao.service';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { LoadingIndicadoresService } from '../../../../loading-indicadores/loading-indicadores.service';

@Component({
  selector: 'app-cadastro-adicionar-orgao',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './cadastro-adicionar-orgao.component.html',
  styleUrl: './cadastro-adicionar-orgao.component.scss'
})

export class CadastroAdicionarOrgaoComponent implements OnInit {
  data = [];
  public listaOrg: orgao[] = [];
  novoItem: orgao = new orgao();

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private loadingService: LoadingIndicadoresService,
    private orgaoService: OrgaoService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }
  submitForm() {
    this.loadingService.show();
    if (this.novoItem.codigo == undefined || this.novoItem.descricao == "") {
      this.loadingService.hidePosto();
      alert("Digite todos os campos obrigatorios!")
      return;
    }

    console.log('Novo item', this.novoItem);
    this.orgaoService.adicionarItem(this.novoItem).subscribe(() => {
      this.loadingService.hidePosto();
      console.log('Novo item adicionado com sucesso', this.novoItem);
      this.novoItem = new orgao();
      this.router.navigate(['orgao-listagem']);
    }, error => {
      this.loadingService.hidePosto();
      console.error('Erro ao adicionar novo item:', error);
    });
  }

  Limpar()
  {
    this.novoItem.codigo = undefined;
    this.novoItem.id = null;
    this.novoItem.codigoCentralizado = "";
    this.novoItem.descricao = "";
    this.novoItem.nomeApresentacao = "";
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let idEditar = params['IdEditar'];
      if(idEditar!=undefined)
        this.AbrirEditarItem(idEditar);
    });
  }

  AbrirEditarItem(id: string | null): void {
    console.log(id)
    if (id != undefined) {
      this.orgaoService.editarItem(id?.toString()).subscribe(
        (response) => {
          console.log(response);
          let p = response["Orgao"];

          this.novoItem.id = p['id'];
          this.novoItem.codigo = p['codigo'];
          this.novoItem.codigoCentralizado = p['codigoCentralizado'];
          this.novoItem.descricao = p['descricao'];
          this.novoItem.nomeApresentacao = p['nomeApresentacao'];
        },
        (error) => {
          console.error('Erro ao excluir o item:', error);
        }
      );
    }
  }
}
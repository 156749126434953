import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { PostoService } from '../../../../service/posto.service';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { RegiaoService } from '../../../../service/regiao.service';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { AuthService } from '../../../../service/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { StatusPostoAgendaService } from '../../../../service/status-posto-agenda.service';
import { DateTimeFormatPipe } from '../../../../utilidades/date-time-format.pipe';

@Component({
  selector: 'app-cadastro-listagem-status-posto-agenda',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './cadastro-listagem-status-posto-agenda.component.html',
  styleUrl: './cadastro-listagem-status-posto-agenda.component.scss',
  providers: [DateTimeFormatPipe]
})

export class CadastroListagemStatusPostoAgendaComponent implements OnInit {
  data = [];
  public listaPos: posto[] = [];
  pesquisaDesc: string  = "";
  pesquisaStatus: string  = "";
  pesquisaCCentralizado: string  = "";

  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  postoEditado:any;
  postoSalvo:any;
  postoExcluido: any;


  @ViewChild('mySelect') mySelect: any;
  @ViewChild('mySelectFiltro') mySelectFiltro: any;
  @ViewChild('mySelectFiltroStatus') mySelectFiltroStatus: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private statusPostoAgendaService: StatusPostoAgendaService,
    public dialog: MatDialog,
    public dateTimeFormatPipe: DateTimeFormatPipe,
    private authService: AuthService,
    private oauthService: OAuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }

  onOptionChangeFiltroStatus(newValue: string) {
    this.pesquisaStatus = newValue;
  }

  ngOnInit() {
    this.Pesquisa();
  }

  MudaFiltro()
  {
    this.pageIndex =0;
    this.Pesquisa()
  }

  Pesquisa() {
    console.log("STATUSSS",this.pesquisaStatus);
    this.listaPos = [];
    this.statusPostoAgendaService.getPaginacao(this.pageIndex, this.pageSize,this.pesquisaDesc, this.pesquisaCCentralizado, this.pesquisaStatus).pipe(
    ).subscribe((response) => {
      this.listaPos = []
      this.data = response["StatusPostoAgenda"];
      this.totalPages = response["totalPages"];
      let i = 0;
      console.log(response);
    });
  }

  pageEvent: PageEvent | undefined;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalPages = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.Pesquisa();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  validarRoleEditar(){
    let role = this.authService.role();
    if(role == 'superuser' || role == 'superintendencia' || role == 'gestorregional'){
        return true;
    }
    return false;
  }

  validarRole(){
    let role = this.authService.role();
    if(role == 'superuser' || role == 'superintendencia'){
        return true;
    }
    return false;
  }

}

<section>
 <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            <td class="nomeTela">
                                Listagem de Serviço
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>
  <main>
    <section class="pesq-section">

      <form class="form-class" data-toggle="collapse" (ngSubmit)="MudaFiltro()">

        <table>
          <tbody>
            <tr>
              <td>
                <div class="form-div p-3">
                  <label for="pesquisaCodigo" class="form-label">Codigo do Agenda:</label>
                  <input type="text" class="form-control" placeholder="Codigo" id="pesquisaCodigo" name="pesquisaCodigo"
                    [(ngModel)]="pesquisaCodigo" required>
                </div>
              </td>
              <td>
              <td>
                <div class="form-div p-3">
                  <label for="pesquisaCodCentralizado" class="form-label">Codigo Centralizado:</label>
                  <input type="text" class="form-control" placeholder="Codigo Centralizado" id="pesquisaCodCentralizado"
                    name="pesquisaCodCentralizado" [(ngModel)]="pesquisaCodCentralizado" required>
                </div>
              </td>
              <td>
              <td>
                <div class="form-div p-3">
                  <label for="pesquisaDesc" class="form-label">Descrição:</label>
                  <input type="text" class="form-control" placeholder="Descrição" id="pesquisaDesc" name="pesquisaDesc"
                    [(ngModel)]="pesquisaDesc" required>
                </div>
              </td>
              <td>
                <div class="form-div p-3">
                  <label for="regiao" class="form-label">Orgão:</label>
                  <select #mySelectFiltro name="select" aria-placeholder="Orgão" class="form-select"
                    (change)="onOptionChangeFiltro(mySelectFiltro.value)">
                    <option value=""></option>
                    <option *ngFor="let objeto of listaOrg" value={{objeto.id}}>{{objeto.descricao}}</option>
                    <option value="-1">Sem Orgão</option>
                  </select>
                </div>
              </td>
              <td>
                <div class="div-button mb-3 p-3" style="margin-top: 47px;">
                  <button id="class-button" type="submit" class="btn btn-primary">Pesquisar</button>
                </div>
              </td>
              @if(validarRole() == true){
                <td style="position: fixed; bottom: 13px; right: 22px;">
                    <a [routerLink]="['/servico-cadastro']"><button id="class-button" style="border-radius: 50%;width: 45px;height: 45px;"
                        class="btn btn-success"><i class="fas fa-plus"></i></button></a>
                  </td>
              }
            </tr>
          </tbody>
        </table>
      </form>
    </section>
    <!--Tabela -->
    <section class="main-section-table">
      <table class="table">
        <!--toJsonBody-->
        <thead>

          <tr>
            <th scope="col" class="col" (click)="MudaOrdencacao('codigo agenda')">
              @if(CampoOrdenacao == 'codigo agenda')
              {
                @if(OrdemOrdenacao == "crescente")
                {
                  <i class="fas fa-chevron-circle-down"></i>
                }
                @else
                {
                  <i class="fas fa-chevron-circle-up"></i>
                }
              }
              Codigo do Agenda

             </th>
            <th scope="col"  class="col" (click)="MudaOrdencacao('codigo centralizaco')">
              @if(CampoOrdenacao == 'codigo centralizaco')
              {
                @if(OrdemOrdenacao == "crescente")
                {
                  <i class="fas fa-chevron-circle-down"></i>
                }
                @else
                {
                  <i class="fas fa-chevron-circle-up"></i>
                }
              }
              Codigo Centralizado</th>
            <th scope="col"  class="col" (click)="MudaOrdencacao('descricao')">
              @if(CampoOrdenacao == 'descricao')
              {
                @if(OrdemOrdenacao == "crescente")
                {
                  <i class="fas fa-chevron-circle-down"></i>
                }
                @else
                {
                  <i class="fas fa-chevron-circle-up"></i>
                }
              }
              Descrição</th>
              <th scope="col"  class="col">
                Descrição para Vagas
              </th>
            <th scope="col"  class="col" (click)="MudaOrdencacao('orgao')">
              @if(CampoOrdenacao == 'orgao')
              {
                @if(OrdemOrdenacao == "crescente")
                {
                  <i class="fas fa-chevron-circle-down"></i>
                }
                @else
                {
                  <i class="fas fa-chevron-circle-up"></i>
                }
              }
              Orgão
            </th>
            <!--<th scope="col">Serviços do posto</th>
            <th scope="col">Órgãos do posto</th>-->
            @if(validarRole() == true){
                <th scope="col" class="col" >Funções</th>
            }
          </tr>
        </thead>
        <tbody>
          @for (p of listaSer; track $index) {
          <tr>
            <td style="font-weight: bold;">{{p.codigo}}</td>
            <td>{{p.codigoCentralizado}}</td>
            <td>{{p.descricao}}</td>
            <td>{{p.descricaoVagas}}</td>
            <td>{{p.orgao?.codigo}} - {{p.orgao?.descricao}}</td>
            <!--<td></td>
            <td></td>-->
            @if(validarRole() == true){
                <td>
                    <a class="btn btn-primary" style="margin-right: 10px;" [routerLink]="['/servico-cadastro']"
                    [queryParams]="{IdEditar: p.id}">Editar</a>
                  <button class="btn btn-danger" (click)="excluirItem(p.id)">Excluir</button>
                </td>
            }
          </tr>
          }
        </tbody>
      </table>

      <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
        [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator>
    </section>


  </main>
</section>

import { Component } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { RelatorioService } from '../../../service/relatorio.service';
import { CommonModule, JsonPipe } from '@angular/common';

import { OrgaoService } from './../../../service/orgao.service';
import { PostoService } from '../../../service/posto.service';
import { ServicoService } from '../../../service/servico.service';
import { FormsModule } from '@angular/forms';
import { interval } from 'rxjs';
import { RegiaoService } from '../../../service/regiao.service';
import { AuthService } from '../../../service/auth.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { MultipleSelectionSelectAllComponent } from '../../../utilidades/multiple-selection-select-all/multiple-selection-select-all.component';
import { Console } from 'console';

@Component({
  selector: 'app-vagas-disponiveis-relatorio',
  standalone: true,
  imports: [
    MatButtonModule, MatButtonToggleModule, MatTableModule, JsonPipe, FormsModule,
    MatSidenavModule,
    MatCardModule,
    FormsModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule, MultipleSelectionSelectAllComponent],
  templateUrl: './vagas-disponiveis-relatorio.component.html',
  styleUrl: './vagas-disponiveis-relatorio.component.scss'
})

export class VagasDisponiveisRelatorioComponent {

  constructor(
    private relatorioService: RelatorioService,
    private postoService: PostoService,
    private orgaoService: OrgaoService,
    private regiaoService: RegiaoService,
    private authService: AuthService) { }

  public parametro: any;
  public resultado = Array<any>();
  public listaOrgao: any = [];
  public listaDatas: any = [];
  public listaPostos: any = [];
  public listaRegiao: any = [];
  public orgaoAtual: any;
  public newFilter: any = [];
  public quantidadePostosNewFilter: any;
  public filterKeywordPosto: any = [];
  public filterKeywordRegiao: any = [];
  valorPesquisado: string | undefined = undefined;
  usuario: any;
  perfil: any;
  larguraTela: number = window.innerWidth;
  RegiaoSelecionada: any;
  PostosSelecionados: any[] = [];

  ngOnInit() {
    this.usuario = localStorage.getItem('salaSituacao');
    this.regiaoService.getRegiaoUsuario(this.usuario).pipe().subscribe((element: any) => {
      element.Regiao.forEach((regiao: any) => {
        this.listaRegiao.push(regiao);
      });
      this.listaRegiao.sort(function (a: any, b: any) {
        if (a.codigo > b.codigo) {
          return 1;
        }
        if (a.codigo < b.codigo) {
          return -1;
        }
        return 0;
      })
    })
    this.perfil = this.authService.role();
    this.relatorioService.findById(1).pipe().subscribe((resultado: any) => {
      var json = JSON.parse(resultado.parametro)
      this.parametro = json;
    });

    this.postoService.getPostoUsuario(this.usuario).pipe().subscribe((json: any) => {
      json.Posto.forEach((postos: any) => {
        if (postos.regiao) {
          var regiao: any = { id: postos.regiao.id }

          var posto: any = { descricao: postos.descricao.toUpperCase(), codigoCentralizado: postos.codigoCentralizado, idRegiao: postos.regiao.id, regiaocodigo: postos.regiao.codigo, regiao: regiao }
          this.listaPostos.push(posto);
        }
      });
      this.listaPostos.sort(function (a: any, b: any) {
        if (a.descricao > b.descricao) {
          return 1;
        }
        if (a.descricao < b.descricao) {
          return -1;
        }
        return 0;
      })
    })

    this.listarServicos();
    this.listarDatas();
    this.popularTabela();
  }


  public popularTabela() {

    this.resultado = [];
    this.postoService.getPostoUsuario(this.usuario).pipe().subscribe((element: any) => {
      element.Posto.forEach((posto: any) => {
        var idPosto: number = Number(posto.codigoCentralizado)
        var descPosto: string = posto.descricao
        this.relatorioService.findRelatorio(1, posto).pipe().subscribe((result: any) => {
          var listaOrgaosByNome: any = []
          //console.log(result);
          for (var i in result.orgaosLabel) {
            var id: number = Number(result.orgaosLabel[i].id);
            if (this.listaOrgao != null) {
              for (var indexOrgaos in this.listaOrgao) {
                if (this.listaOrgao[indexOrgaos].desc == "undefined") {
                  //console.log(this.listaOrgao[indexOrgaos]);
                  break;
                } else {
                  if (this.listaOrgao[indexOrgaos].codigoCentralizado == id &&
                    this.listaOrgao[indexOrgaos].desc !== undefined && this.listaOrgao[indexOrgaos].desc.indexOf('PREFEITURA') == -1) {
                    listaOrgaosByNome.push([this.listaOrgao[indexOrgaos].desc, result.orgaosLabel[i].desc, i]);
                    break;
                  }
                }
              }
            }
            else {
              listaOrgaosByNome.push([Object.values(result.orgaosLabel[i])[0], result.orgaosLabel[i].desc, i]);
              break;
            }
          }

          listaOrgaosByNome.sort(function (a: any, b: any) {
            if (a[0] == 'IIRGD' && b[0] == 'IIRGD') {
              return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
            } else if (a[0] == 'IIRGD') {
              return -1;
            } else if (b[0] == 'IIRGD') {
              return 1;
            } else if (a[0] == 'DETRAN' && b[0] == 'DETRAN') {
              return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
            } else if (a[0] == 'DETRAN') {
              return -1;
            } else if (b[0] == 'DETRAN') {
              return 1;
            } else if (a[0] == 'Detran.SP - Drive Thru' && b[0] == 'Detran.SP - Drive Thru') {
              return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
            } else if (a[0] == 'Detran.SP - Drive Thru') {
              return -1;
            } else if (b[0] == 'Detran.SP - Drive Thru') {
              return 1;
            } else if (a[0] == 'CDHU' && b[0] == 'CDHU') {
              return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
            } else if (a[0] == 'CDHU') {
              return -1;
            } else if (b[0] == 'CDHU') {
              return 1;
            } else if (a[0] == ('SEC EDUCAÇÃO') && b[0] == ('SEC EDUCAÇÃO')) {
              return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
            } else if (a[0] == ('SEC EDUCAÇÃO')) {
              return -1;
            } else if (b[0] == ('SEC EDUCAÇÃO')) {
              return 1;
            } else if (a[0] == 'Detran.SP - Drive Thru' && b[0] == 'Detran.SP - Drive Thru') {
              return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
            } else if (a[0] == 'Detran.SP - Drive Thru') {
              return -1;
            } else if (b[0] == 'Detran.SP - Drive Thru') {
              return 1;
            }

            return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
          });
          console.log("listaOrgaosByNome",listaOrgaosByNome)
          var listaOrgaoServico: any = []
          for (var indexOrgaos in listaOrgaosByNome) {
            listaOrgaoServico.push([listaOrgaosByNome[indexOrgaos][1], listaOrgaosByNome[indexOrgaos][0], result.data[listaOrgaosByNome[indexOrgaos][2]]])
          }

          if (listaOrgaoServico.length > 0) {
            var posto: any = { nomePosto: descPosto, idPosto: idPosto, listaOrgaoServico: listaOrgaoServico }
            this.resultado.push(posto)
          }

          if (this.resultado.length >= 1) {
            this.resultado.sort(function (a, b) {
              if (a.nomePosto > b.nomePosto) {
                return 1;
              }
              if (a.nomePosto < b.nomePosto) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });
          }
        })
      });
    }, (error: any) => { })

  }

  public listarServicos() {
    this.orgaoService.getOrgao().pipe(
    ).subscribe((response) => {
      //console.log(response);
      response["Orgao"].forEach((elementOrgao: any) => {
        var idOrgao: number = Number(elementOrgao["codigoCentralizado"])
        if (idOrgao == 2) {
          this.listaOrgao.push({ desc: 'IIRGD', codigoCentralizado: elementOrgao["codigoCentralizado"] })
        } else if (idOrgao == 86) {
          this.listaOrgao.push({ desc: 'SEC EDUCAÇÃO', codigoCentralizado: elementOrgao["codigoCentralizado"] })
        } else if (idOrgao == 46) {
          this.listaOrgao.push({ desc: 'TRE-SP', codigoCentralizado: elementOrgao["codigoCentralizado"] })
        } else {
          this.listaOrgao.push({ desc: elementOrgao["descricao"], codigoCentralizado: elementOrgao["codigoCentralizado"] })
        }
      });
      console.log(this.listaOrgao);
    });
  }

  public listarDatas() {
    for (let sum = 0; sum < 14; sum++) {

      let data = new Date();
      data.setDate(data.getDate() + sum);
      var dia = data.getDate();
      var mes = data.getMonth() + 1;

      var juntarDiaMes: string;
      if (dia < 10 && mes < 10) {
        juntarDiaMes = `0${dia}/0${mes}`;
      } else if (dia < 10) {
        juntarDiaMes = `0${dia}/${mes}`;
      } else if (mes < 10) {
        juntarDiaMes = `${dia}/0${mes}`;
      } else {
        juntarDiaMes = `${dia}/${mes}`;
      }

      this.listaDatas.push(juntarDiaMes);
    }
  }

  public atualizarOrgaoAtual(orgao: any) {
    this.orgaoAtual.set(orgao);
    return console.log(this.orgaoAtual);
  }

  public veririficarValores(valores: any) {
    if (isNaN(valores.h)) {
      return true
    } else {
      return false
    }
  }

  public validarOrgaoAtual(orgao: any) {
    if (this.orgaoAtual != orgao) {
      this.orgaoAtual = orgao;
      return true;
    } else {
      return false;
    }
  }

  public paint(valor: any) {
    if (valor.h === 0) {
      return 'whiter';
    } else if (!isNaN(valor.h)) {
      var s = valor.v / valor.h
      if (!isNaN(s)) {
        if (valor.v === 0) {
          return this.parametro.cor_a;
        } else if (s >= this.parametro.faixa_b && s < this.parametro.faixa_c || s < this.parametro.faixa_b) {
          return this.parametro.cor_b;
        } else if (s >= this.parametro.faixa_c) {
          return this.parametro.cor_c;
        }
      }
    }
    return this.parametro.cor_a;
  }


  filterPosto(posto: any) {
    var postosIguais = false;
    if (this.newFilter.length > 0) {
      this.newFilter.forEach((element: any, index: number) => {
        if (posto.codigoCentralizado == element.codigoCentralizado) {
          this.newFilter.splice(index, 1);
          postosIguais = true;
        }
      });
      if (!postosIguais == true) {
        this.newFilter.push(posto);
      }
      //console.log(this.newFilter)
    } else {
      this.newFilter.push(posto);
    }
    this.quantidadePostosNewFilter = this.newFilter.length;
  }

  filterRegiao(regiao: any) {
    //console.log(regiao)
    var postosIguais = false;
    this.listaPostos.forEach((postos: any) => {
      if (regiao.id == postos.idRegiao) {
        if (this.newFilter.length > 0) {
          this.newFilter.forEach((element: any, index: number) => {
            if (postos.codigoCentralizado == element.codigoCentralizado) {
              this.newFilter.splice(index, 1);
              postosIguais = true;
            }
          });
          if (!postosIguais == true) {
            this.newFilter.push(postos);
          }
          //console.log(this.newFilter)
        } else {
          this.newFilter.push(postos);
        }
      }
    });
    this.quantidadePostosNewFilter = this.newFilter.length;
  }

  clearFilter() {
    this.newFilter = [];
  }

  searchByKeywordPosto(event: any) {
    if (event == null || event.target.value == "") {
      this.filterKeywordPosto = this.listaPostos;
      this.valorPesquisado = undefined;
    }
    else {
      const valorDigitado = event.target.value;
      this.valorPesquisado = valorDigitado;
      this.filterKeywordPosto = this.listaPostos.filter((x: any) => x.descricao.toLowerCase().trim().includes(valorDigitado.toLowerCase().trim()));
      this.filterKeywordPosto.sort((a: any, b: any) => a.descricao - b.descricao);
    }
  }

  searchByKeywordRegiao(event: any) {
    if (event == null || event.target.value == "") {
      this.filterKeywordRegiao = this.listaRegiao;
      this.valorPesquisado = undefined;
    }
    else {
      const valorDigitado = event.target.value;
      this.valorPesquisado = valorDigitado;
      this.filterKeywordRegiao = this.listaRegiao.filter((x: any) => x.descricao.toLowerCase().trim().includes(valorDigitado.toLowerCase().trim()));
      this.filterKeywordRegiao.sort((a: any, b: any) => a.descricao - b.descricao);
    }
  }

  filtrarPesquisa() {
    this.newFilter = [];

    this.PostosSelecionados.forEach(element => {
      var posto: any = { codigoCentralizado: element }
      this.newFilter.push(posto);
      this.quantidadePostosNewFilter = this.newFilter.length;
    });

  }

  onSelecionar(valor: string) {
    this.RegiaoSelecionada = valor; // Atualiza o valor quando o select muda
  }

  processarInfo(info: any) {
    this.PostosSelecionados = info;
  }
}

<section style="height: auto;">
  <nav>
    <ol class="divbusca">
      <table>
        <tbody>
          <tr>
            <td class="tdTitilo">
              <label for="regiao" class="form-label">Região:</label>
            </td>
            <td class="tdCampo">
              <select (change)="onSelecionar(mySelectFiltroRegiao.value)" #mySelectFiltroRegiao name="select"
                aria-placeholder="Regiao" class="form-select">
                <option value="">Todos</option>
                <option *ngFor="let objeto of listaRegiao" value={{objeto.id}}>{{objeto.descricao}}</option>

              </select>
            </td>
            <td class="tdTitilo">
              <label for="posto" class="form-label">Posto:</label>
            </td>
            <td class="tdCampo">
              <app-multiple-selection-select-all [lista]="listaPostos" [width]="larguraTela/4.5" [regiaoSelecionada]="RegiaoSelecionada"
                (infoClicada)="processarInfo($event)"></app-multiple-selection-select-all>
            </td>
            <td class="tdTitilo">
              <label for="posto" class="form-label">Período da pesquisa:</label>
            </td>
            <td class="tdCampo">
              <div style="height: 34px;">
                <mat-form-field style="height: 63px;">
                  <mat-date-range-input [rangePicker]="picker">
                    <input [(ngModel)]="filtroDataInicio" matStartDate placeholder="Data de Início">
                    <input [(ngModel)]="filtroDataFim" matEndDate placeholder="Data Fim">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
            </td>
            <td class="tdTitilo">
              <button class="btn btn-primary" (click)="filtrarPesquisa()" id="class-button"
                type="submit">Pesquisar</button>
            </td>
          </tr>
        </tbody>
      </table>
    </ol>
  </nav>
</section>
<section>
  <section style="height: auto;">
    <nav>
      <ol class="cd-breadcrumb custom-separator">
        <table>
          <tbody>
            <tr>
              <td class="nomeTela">
                Relatório de Incidência
              </td>
            </tr>
          </tbody>
        </table>
      </ol>
    </nav>
  </section>
  @if (SemDados == true) {
  <h4 class="class-h4">Não exite dados para serem mostrados</h4>
  }
  <div style="display: flex; justify-content: end;">
    <button type="button" (click)="exportTableToPDF()" class="btn btn-secondary" style="margin-right: 10px;">
      Exportar PDF<i class="fas fa-file-pdf fa-2xl" style="margin-left: 10px;"></i>
    </button>
    <button (click)="exportTableToExcel()" type="button" class="btn btn-secondary" style="margin-right: 10px;">
      Exportar Excel
      <i class="fas fa-file-excel fa-2xl" style="margin-left: 10px;"></i>
    </button>

    <button (click)="exportTableToExcelInLine()" type="button" class="btn btn-secondary" style="margin-right: 10px;">
      Exportar CSV 
      <i class="fas fa-file-csv fa-2xl" style="margin-left: 10px;"></i>
    </button>
  </div>
  <div class="style-tabela">
    <table id="table1" border="0" cellpadding="0" cellspacing="0" width="75%" style="margin-left: 6%;margin-top: 1%;"
      #table1>
      <tbody>
        <tr>
          <th class="tituloTabela">Posto</th>
          <th class="tituloTabela">Fila</th>
          <td class="tituloTabela">Data Início / Hora</td>
          <td class="tituloTabela">Data Fim / Hora</td>
          <td class="tituloTabela">Tempo</td>
          <td class="tituloTabela">Cidadão na Espera</td>
          <td class="tituloTabela">Pontos Abertos</td>
        </tr>
        @for(item of listaIndPosto; track listaIndPosto; let indexp = $index){
        @if(item.indicadorAlertaPermanencia != undefined){
        <tr>
          <th [attr.rowspan]="item.indicadorAlertaPermanencia.length*2">{{item.descricao}}</th>
          <td rowspan="2">{{item.indicadorAlertaPermanencia[0].descFila}}</td>
          <td>{{dateTimeFormatPipe.transform(item.indicadorAlertaPermanencia[0].dataInicio)}}</td>
          <td>{{dateTimeFormatPipe.transform(item.indicadorAlertaPermanencia[0].data_fim)}}</td>
          <td>{{timeFormatPipe.transform((item.indicadorAlertaPermanencia[0].tempo))}}</td>
          <td>{{item.indicadorAlertaPermanencia[0].qtdCidadaoEmEspera}}</td>
          <td>{{item.indicadorAlertaPermanencia[0].qtdPontosAbertos}}</td>
        </tr>
        <tr>
          @if(item.indicadorAlertaPermanencia[0].status === StatusIndicadorAlerta.AO_INCLUIR)
          {
          <td colspan="5" class="StatusAoIncluir">Status: Justificativa Pendente</td>
          }
          @if(item.indicadorAlertaPermanencia[0].status === StatusIndicadorAlerta.PEDENTE_POSTO)
          {
          <td colspan="5" class="StatusPendentePosto">Status: Pendente com Posto</td>
          }
          @if(item.indicadorAlertaPermanencia[0].status === StatusIndicadorAlerta.PENDENTE_VALIDACAO &&
          item.indicadorAlertaPermanencia[0].mensagens != undefined)
          {
          <td class="StatusPendenteValidacao">Status: Pendente Validação</td>
          <td colspan="4" class="StatusPendenteValidacao">
            {{item.indicadorAlertaPermanencia[0].mensagens[item.indicadorAlertaPermanencia[0].mensagens.length -
            1].mensagem}}</td>
          }
          @if(item.indicadorAlertaPermanencia[0].status === StatusIndicadorAlerta.FECHADO &&
          item.indicadorAlertaPermanencia[0].mensagens != undefined &&
          item.indicadorAlertaPermanencia[0].mensagens.length != 0)
          {
          <td class="StatusEncerrado">Status: Encerrado</td>
          <td colspan="4" class="StatusEncerrado">
            {{item.indicadorAlertaPermanencia[0].mensagens[item.indicadorAlertaPermanencia[0].mensagens.length -
            1].mensagem}}</td>
          }

        </tr>
        @for(itemFila of item.indicadorAlertaPermanencia; track itemFila; let indexf = $index){
        @if(indexf != 0){
        <tr>
          <td rowspan="2">{{itemFila.descFila}}</td>
          <td>{{dateTimeFormatPipe.transform(itemFila.dataInicio)}}</td>
          <td>{{dateTimeFormatPipe.transform(itemFila.data_fim)}}</td>
          <td>{{timeFormatPipe.transform(itemFila.tempo)}}</td>
          <td>{{itemFila.qtdCidadaoEmEspera}}</td>
          <td>{{itemFila.qtdPontosAbertos}}</td>
        </tr>
        <tr>
          @if(itemFila.status === StatusIndicadorAlerta.AO_INCLUIR)
          {
          <td colspan="5" class="StatusAoIncluir">Status: Justificativa Pendente</td>
          }
          @if(itemFila.status === StatusIndicadorAlerta.PEDENTE_POSTO)
          {
          <td colspan="5" class="StatusPendentePosto">Status: Pendente com Posto</td>
          }
          @if(itemFila.status === StatusIndicadorAlerta.PENDENTE_VALIDACAO && itemFila.mensagens != undefined)
          {
          <td class="StatusPendenteValidacao">Status: Pendente Validação</td>
          <td colspan="4" class="StatusPendenteValidacao" style="font-weight: bold !important">
            {{itemFila.mensagens[itemFila.mensagens.length - 1].mensagem}}
          </td>
          }
          @if(itemFila.status === StatusIndicadorAlerta.FECHADO && itemFila.mensagens != undefined &&
          itemFila.mensagens.length != 0)
          {
          <td class="StatusEncerrado">Status: Encerrado</td>
          <td colspan="4" class="StatusEncerrado" style="font-weight: bold;">
            {{itemFila.mensagens[itemFila.mensagens.length - 1].mensagem}}</td>
          }
        </tr>
        }
        }
        }
        }
      </tbody>
    </table>
  </div>
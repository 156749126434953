import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { indicador } from '../../../../modal/Indicador';
import { IndicadorService } from '../../../../service/Indicador.service';
import { CalculoCores } from '../../../../modal/CalculoCores';
import { Campos } from '../../../../modal/Campos';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { EditorConfigService } from '../../../../service/editorTextoConfig.service';
import { QuillModule } from 'ngx-quill';

@Component({
  selector: 'app-cadastro-adicionar-indicador',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule,
    QuillModule
  ],
  templateUrl: './cadastro-adicionar-indicador.component.html',
  styleUrl: './cadastro-adicionar-indicador.component.scss'
})

export class CadastroAdicionarIndicadorComponent implements OnInit {
  data = [];
  public listaIndEtapa1: indicador[] = [];
  public listaIndAllEtapas: indicador[] = [];
  public listaIndAllEtapasComFarol: indicador[] = [];
  novoItem: indicador = new indicador();
  EhEtapa1 = true;
  EhPorcentagem = false;
  EhMultiConsultaValor = false
  EhRegistroComCampos = false;
  editorConfig: any;
  EhCopiaCampo = false;
  EhFarolCopia = false;
  CalculoCorValorPadrao = "{\"regras\": [{\"intervalo\": \"NumeroMaior-NumeroMenor\",\"cor\": \"Verde\"},{\"intervalo\": \"NumeroMaior-NumeroMenor\",\"cor\": \"Amarelo\"},{\"intervalo\": \"NumeroMaior-NumeroMenor\",\"cor\": \"Vermelho\"}]}"
  CalculoCorFormulaPadrao = "{\"caso\":[	{\"nome\":\"NomeConsulta1\",\"tipo\":\"==\",\"numero\":\"0\",\"regras\":[{\"intervalo\":\"100-0\",\"cor\":\"Vermelho\"}]},{\"nome\":\"NomeConsulta2\",\"tipo\":\"==\",\"numero\":\"0\",\"regras\":[{\"intervalo\":\"100-0\",\"cor\":\"Verde\"}]},{\"nome\":\"NomeConsulta\",\"tipo\":\"<=\",\"numero\":\"Numero\",\"regras\":[{\"intervalo\":\"NumeroMaior-NumeroMenor\",\"cor\":\"Verde\"},{\"intervalo\":\"NumeroMaior-NumeroMenor\",\"cor\":\"Amarelo\"},{\"intervalo\":\"NumeroMaior-NumeroMenor\",\"cor\":\"Vermelho\"}]}]}";
  CalculoCorCampoPadrao = "{\"caso\":[	{\"nome\":\"NomeCampo\",\"tipo\":\"==\",\"numero\":\"0\",\"regras\":[{\"intervalo\":\"100-0\",\"cor\":\"Vermelho\"}]},{\"nome\":\"NomeCampo\",\"tipo\":\"==\",\"numero\":\"0\",\"regras\":[{\"intervalo\":\"100-0\",\"cor\":\"Verde\"}]},{\"nome\":\"NomeCampo\",\"tipo\":\"<=\",\"numero\":\"Numero\",\"regras\":[{\"intervalo\":\"NumeroMaior-NumeroMenor\",\"cor\":\"Verde\"},{\"intervalo\":\"NumeroMaior-NumeroMenor\",\"cor\":\"Amarelo\"},{\"intervalo\":\"NumeroMaior-NumeroMenor\",\"cor\":\"Vermelho\"}]}]}";
  public TravaEtapa = false;
  PossicaoPadrao = "{Campo;DIREITA/ESQUERDA}";
  HTMLPadrao = "{\"caso\":[{\"nome\": \"CAMPO\",\"tipo\": \"==\",\"valor\": \"VALOR\",\"innerHTML\": \"HTML\"}]}";
  EhCalculoCorPadrao = 0;
  @ViewChild('mySelectIndicador') mySelectIndicador: any;
  @ViewChild('mySelectOrdem') mySelectOrdem: any;
  @ViewChild('mySelectIndicadorCopia') mySelectIndicadorCopia: any;
  @ViewChild('mySelectIndicadorCopiaFarol') mySelectIndicadorCopiaFarol: any;
  options: any[] = [
    { value: '0', label: '' },
    { value: '2', label: 'AGGREGATION (retorno padrão)' },
    { value: '21', label: 'AGGREGATION Key (Padrão com key especial)' },
    { value: '3', label: 'PERCENT (valor2/valor1*100)' },
    { value: '4', label: 'PERCENT2 (valor1/valor2*100)' },
    { value: '5', label: 'PERCENTWithCOUNT (((valor2-valor1)/(valor4-valor3))*100)' },
    { value: '6', label: 'PERCENTWithCOUNT2 (((valor4-valor3)/(valor2-valor1))*100)' },
    { value: '16', label: 'HORA (milisegundos para minutos)' },
    { value: '17', label: 'HORA (milisegundos para segundos)' },
    { value: '18', label: 'HORA (milisegundos)' },
    { value: '19', label: 'HORA (minutos)' }
  ];
  optionsCampos: any[] = [
    { value: '0', label: '' },
    { value: '20', label: 'Looping' },
    { value: '22', label: 'buckets' },
    { value: '21', label: 'buckets - Key Unica' },
    { value: '2', label: 'Texto' },
    { value: '24', label: 'Key ou DOC COUNT' },
    { value: '3', label: 'Pocentagem' },
    { value: '16', label: 'HORA' },
    { value: '23', label: 'Looping - Final' },
    { value: '25', label: 'DIA E HORA' },
  ];
  optionsTipoGraficoIndicador: any[] = [
    { value: '0', label: '' },
    //{ value: '1', label: 'Grafico Adicional - Unico' },
    { value: '4', label: 'Grafico Adicional - Loop Diario' },
    { value: '2', label: 'Grafico Tela Completa' },
    { value: '3', label: 'Alerta' }
  ];
  optionsTipoGraficoCampo: any[] = [
    { value: '0', label: '' },
    { value: '1', label: 'Titulo' },
    { value: '3', label: 'Eixo Horizontal' },
    { value: '4', label: 'Valor 1' },
    { value: '5', label: 'Valor 2' },
    { value: '6', label: 'Dias do Alerta' },
    { value: '7', label: 'Informação adicional no ToolTip' },
    { value: '8', label: 'Informação adicinal no quadro geral' },
  ];
  listaTipoResultadoPorcentagem: number[] = [3, 4, 5, 6];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private indicadorService: IndicadorService,
    private editorConfigHtml: EditorConfigService,
    public dialog: MatDialog) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }

  }





  consultasCor: CalculoCores[] = [];
  consultasFarol: CalculoCores[] = [];
  consultasValor: CalculoCores[] = [];
  campos: Campos[] = [];
  datacamposload = [];
  isDropdownOpen: boolean = false;


  // ngOnInit() {
  //   this.editorConfig = this.editorConfigHtml.getEditorConfig();
  // }



  adicionarConsultaValor() {
    this.consultasValor.push({ nome: '', indice: '', consulta: '', tipoResultado: undefined });
  }
  validateAndFormat(index: number, TipoCampo: string): void {
    switch (TipoCampo) {
      case "CAMPO":
        this.campos[index].componenteGraficoHtml = this.newJson(this.campos[index].componenteGraficoHtml);
        break;
      case "CAMPO_LINK":
        this.campos[index].linkVariaveis = this.newJson(this.campos[index].linkVariaveis);
        break;
      case "CORES":
        this.consultasCor[index].consulta = this.newJson(this.consultasCor[index].consulta);
        break;
      case "Valor":
        this.novoItem.consulta = this.newJson(this.novoItem.consulta);
        break;
      case "MultiValor":
        this.consultasValor[index].consulta = this.newJson(this.consultasValor[index].consulta);
        break;
      case "FAROL":
        this.consultasFarol[index].consulta = this.newJson(this.consultasFarol[index].consulta);
        break;
    }
  }

  newJson(valor: string | undefined) {
    if (valor == undefined)
      return "";
    try {
      const jsonObject = JSON.parse(valor.replaceAll(/(#[^#]+#)/g, "\"$1\""));
      return JSON.stringify(jsonObject, null, 2).replaceAll("\"#", "#").replaceAll("#\"", "#");
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert('Erro no JSON: ' + error.message);
      } else {
        alert('Erro desconhecido');
      }
      return valor;
    }
  }

  removerConsultaValor(index: number) {
    this.consultasValor.splice(index, 1);
  }
  adicionarCampo() {
    this.campos.push({
      nome: '',
      campoCalculo: '',
      tipoResultado: undefined,
      ordem: 0,
      loopingPai: undefined,
      icone: '',
      naoMostraCampo: false,
      naoMostraTitulo: false,
      orientacaoLoop: '',
      componenteGraficoCss: undefined,
      componenteGraficoHtml: undefined,
      componenteGraficoPossicao: "",
      ehComponenteGrafico: false,
      ehLink: false,
      linkRedirect: undefined,
      linkVariaveis: undefined,
      ehCampoCabecalho: false,
      tipoValorGrafico: undefined,
      nomeConsultaRefentente: undefined,
      ehOcultoPadrao: undefined,
      ehCampoOrdenacao: undefined
    });
  }

  adicionarConsultaFarol() {
    this.consultasFarol.push({ nome: '', indice: '', consulta: '', tipoResultado: undefined });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  handleDropdownItemClick(event: MouseEvent) {
    event.stopPropagation(); // Impede que o evento seja propagado para o dropdown-toggle
    console.log('Dropdown item clicked');
    // Adicione o código aqui para lidar com o clique nos itens do dropdown
  }

  removerCampo(index: number) {
    this.campos.splice(index, 1);
  }

  adicionarConsultaCor() {
    this.consultasCor.push({ nome: '', indice: '', consulta: '', tipoResultado: undefined });
  }

  removerConsultaCor(index: number, consulta: CalculoCores) {
    if (consulta.nome == 'Regra de Valor' || consulta.nome == 'Regra de Retorno da Formula' || consulta.nome == 'Regra de Valor') {
      this.EhCalculoCorPadrao = 0;
    }
    this.consultasCor.splice(index, 1);
  }
  removerConsultaFarol(index: number, consulta: CalculoCores) {
    this.consultasFarol.splice(index, 1);
  }

  SetarRegraPadrao(consulta: CalculoCores, regraUnica: boolean) {
    this.isDropdownOpen = false;
    if (regraUnica)
      this.EhCalculoCorPadrao = 1;
    consulta.nome = 'Regra de Valor';
    consulta.indice = "";
    consulta.tipoResultado = 0;
    consulta.consulta = this.CalculoCorValorPadrao;
  }

  SetarRegraCampos(consulta: CalculoCores) {
    this.isDropdownOpen = false;
    this.EhCalculoCorPadrao = 3;
    consulta.nome = 'Regra de Campos';
    consulta.indice = "";
    consulta.tipoResultado = 0;

    consulta.consulta = this.CalculoCorCampoPadrao;
  }

  SetarRegraDoValorDasFormula(consulta: CalculoCores, regraUnica: boolean) {
    consulta.nome = 'Regra de Retorno da Formula';
    this.isDropdownOpen = false;
    consulta.indice = "";
    consulta.tipoResultado = 0;
    consulta.consulta = this.CalculoCorFormulaPadrao;
    if (regraUnica)
      this.EhCalculoCorPadrao = 2;
  }

  CancelarRegraPadrao(consulta: CalculoCores) {
    this.EhCalculoCorPadrao = 0;
    consulta.nome = "";
    consulta.indice = "";
    consulta.consulta = "";
  }

  mostrarBotaoPreencherAutomaticamente(): boolean {
    return this.consultasCor.length === 1;
  }

  mostrarBotaoPreencherAutomaticamente2(): boolean {
    return this.consultasCor.length != 1;
  }

  SeTiverAlgoEscrito(consulta: CalculoCores): boolean {
    if (consulta.nome != "" || consulta.indice != "" || consulta.consulta != "")
      return false;
    else
      return true;
  }

  submitForm() {
    this.novoItem.calculoCores = this.consultasCor;
    this.novoItem.consultasCalculoFarol = this.consultasFarol;
    this.novoItem.multiplasConsultas = this.consultasValor;
    this.novoItem.camposConsulta = this.campos;
    //console.log(this.novoItem, this.consultasCor);
    if (this.novoItem.codigo == undefined || this.novoItem.nome == "" || this.novoItem.descricao == "" || this.novoItem.etapa == 0 || this.novoItem.corDefault == "" || this.novoItem.ordenacaoOrientacao == "") {
      alert("Digite todos os campos obrigatorios!")
      return;
    }

    if (this.novoItem.etapa == 1)
      //if(this.verificarExistenciaDoCodigo(this.novoItem.codigo)){
      //alert("Digite um Codigo não utilizado anteriormente")
      //return;}

      console.log('Novo item', this.novoItem);
    this.indicadorService.adicionarItem(this.novoItem).subscribe(() => {
      console.log('Novo item adicionado com sucesso', this.novoItem);
      alert("Salvo com sucesso!");
      if (this.novoItem.id == undefined) {
        this.router.navigate(['/indicador-listagem']);
      }

    }, error => {
      console.error('Erro ao adicionar novo item:', error);
    });
  }

  Limpar() {
    this.novoItem = new indicador();
    this.consultasCor = [];
    this.consultasValor = [];
    this.EhEtapa1 = false;
    this.EhCalculoCorPadrao = 0;
    this.EhMultiConsultaValor = false;
    this.EhPorcentagem = false;
  }

  handleInputChange(event: any) {
    const inputValue = event.target.value;
    if (inputValue == 1) {
      this.EhEtapa1 = true;
      this.EhCalculoCorPadrao = 0;
      this.consultasCor = [];
      this.novoItem.codigo = this.obterMaiorCodigo() + 1;
    }
    else
      this.EhEtapa1 = false;
  }
  IdIndicadorCopiaCampo = "";
  IdIndicadorFarolCampo = "";
  onOptionChangeCopiaCampo(newValue: string) {
    console.log(newValue);
    this.IdIndicadorCopiaCampo = newValue;
  }

  onOptionChangeCopiaFarol(newValue: string) {
    this.IdIndicadorCopiaCampo = newValue;
  }
  AbrirCopia() {
    this.EhCopiaCampo = true;
  }

  AbrirCopiaFarol() {
    this.EhFarolCopia = true;
  }
  CopiarCampo() {
    this.campos = [];

    let IndicadorSelecionado = this.listaIndAllEtapas.find(x => x.id == this.IdIndicadorCopiaCampo);
    //this.campos = IndicadorSelecionado?.camposConsulta;
    if (IndicadorSelecionado != undefined && IndicadorSelecionado.camposConsulta != undefined) {
      this.campos = IndicadorSelecionado.camposConsulta;
      this.campos.sort((a, b) => a.ordem - b.ordem);
      this.EhCopiaCampo = false;
    }
  }

  CopiarFarol() {
    this.consultasFarol = [];

    let IndicadorSelecionado = this.listaIndAllEtapasComFarol.find(x => x.id == this.IdIndicadorCopiaCampo);
    //this.campos = IndicadorSelecionado?.camposConsulta;
    if (IndicadorSelecionado != undefined && IndicadorSelecionado.consultasCalculoFarol != undefined) {
      this.consultasFarol = IndicadorSelecionado.consultasCalculoFarol;
      this.novoItem.tempoAtualizacaoCalculoFarol = IndicadorSelecionado.tempoAtualizacaoCalculoFarol;
      this.novoItem.tipoFinal = IndicadorSelecionado.tipoFinal;
      this.novoItem.formulaCalculoFarol = IndicadorSelecionado.formulaCalculoFarol;
      this.EhFarolCopia = false;
    }

  }

  onOptionChange(newValue: string) {
    console.log(newValue);
    let IndicadorSelecionado = this.listaIndEtapa1.find(x => x.codigo == Number.parseInt(newValue))
    this.novoItem.codigo = IndicadorSelecionado?.codigo;
    this.novoItem.nome = IndicadorSelecionado?.nome == undefined ? "" : IndicadorSelecionado?.nome;
    this.novoItem.descricao = IndicadorSelecionado?.descricao == undefined ? "" : IndicadorSelecionado?.descricao;
    this.novoItem.icone = IndicadorSelecionado?.icone == undefined ? "" : IndicadorSelecionado?.icone;
    this.novoItem.tempoAtualizacao = IndicadorSelecionado?.tempoAtualizacao;
    this.novoItem.abreviatura = IndicadorSelecionado?.abreviatura == undefined ? "" : IndicadorSelecionado?.abreviatura;
    this.novoItem.parametro = IndicadorSelecionado?.parametro == undefined ? "" : IndicadorSelecionado?.parametro;
    this.novoItem.formulaValor = IndicadorSelecionado?.formulaValor == undefined ? "" : IndicadorSelecionado?.formulaValor;
    this.novoItem.colecao = IndicadorSelecionado?.colecao == undefined ? "" : IndicadorSelecionado?.colecao;
    this.novoItem.corDefault = IndicadorSelecionado?.corDefault == undefined ? "Cinza" : IndicadorSelecionado?.corDefault;
    this.novoItem.corDefaultDenominador = IndicadorSelecionado?.corDefaultDenominador == undefined ? "" : IndicadorSelecionado?.corDefaultDenominador;
    this.novoItem.corDefaultZero = IndicadorSelecionado?.corDefaultZero == undefined ? "" : IndicadorSelecionado?.corDefaultZero;
    this.novoItem.ordenacaoOrientacao = IndicadorSelecionado?.ordenacaoOrientacao == undefined ? "" : IndicadorSelecionado?.ordenacaoOrientacao;
    this.mySelectOrdem.nativeElement.value = '' + this.novoItem.ordenacaoOrientacao;
    this.novoItem.ordemDashboard = undefined;
    this.novoItem.inativo = IndicadorSelecionado?.inativo == undefined ? false : IndicadorSelecionado?.inativo;
  }

  getCalculoCorIndicadorEtapa1() {
    this.consultasCor = [];
    let IndicadorSelecionado = this.listaIndEtapa1.find(x => x.codigo == this.novoItem.codigo)
    console.log(IndicadorSelecionado);
    IndicadorSelecionado?.calculoCores?.forEach(c => {
      if (c.nome == "Regra de Valor") {
        this.EhCalculoCorPadrao = 1;
      }
      if (c.nome == "Regra de Retorno da Formula") {
        this.EhCalculoCorPadrao = 2;
      }
      this.consultasCor.push(new CalculoCores(
        c.nome,
        c.consulta,
        c.indice,
        c.tipoResultado)
      )
    });
  }

  verificarExistenciaDoCodigo(codigo: number): boolean {
    return this.listaIndEtapa1.some(objeto => objeto.codigo === codigo);
  }

  ngOnInit() {

    this.editorConfig = this.editorConfigHtml.getEditorConfig();
    this.route.queryParams.subscribe(params => {
      let idEditar = params['IdEditar'];

      this.indicadorService.getIndicador().pipe(
      ).subscribe((response) => {
        this.data = response["Indicador"];

        let i = 0;
        console.log(response);
        this.data.forEach(element => {
          i++;
          let consultaCorListInd: CalculoCores[] = [];
          let dataCor: [] = element['calculoCores'];
          if (dataCor != undefined)
            dataCor.forEach(c => {
              consultaCorListInd.push(new CalculoCores(
                c['nome'],
                c['consulta'],
                c['indice'],
                c['tipoResultado'])
              )
            });
          var Ind = new indicador(
            element['id'],
            element['codigo'],
            element['etapa'],
            element['proximaetapa'],
            element['tipo_resultado'],
            element['nome'],
            element['descricao'],
            element['tempoAtualizacao'],
            element['icone'],
            element['consulta'],
            element['parametro'],
            element['colecao'],
            element['versaoindice'],
            element['abreviatura'],
            element['formula'],
            consultaCorListInd
          );
          var camposload: Campos[] = [];
          this.datacamposload = element['camposConsulta'];
          if (this.datacamposload != undefined) {
            this.datacamposload.forEach(c => {
              camposload.push(new Campos(
                c['nome'],
                c['campoCalculo'],
                c['tipoResultado'],
                c['ordem'],
                c['loopingPai'],
                c['icone'],
                c['naoMostraCampo'],
                c['naoMostraTitulo'],
                c['orientacaoLoop'],
                c['ehComponenteGrafico'],
                c['componenteGraficoHtml'],
                c['componenteGraficoCss'],
                c['componenteGraficoPossicao'],
                c['ehLink'],
                c['linkRedirect'],
                c['linkVariaveis'],
                c['ehCampoCabecalho'],
                c['tipoValorGrafico'],
                c['nomeConsultaRefentente'],
                c['ehOcultoPadrao'],
                c['ehCampoOrdenacao']
              )
              )
            });
            camposload.sort((a, b) => a.ordem - b.ordem);
            Ind.camposConsulta = camposload;
            var farolload: CalculoCores[] = [];
            this.datacamposload = element['consultasCalculoFarol'];
            if (this.datacamposload != null) {
              this.datacamposload.forEach(c => {
                farolload.push(new CalculoCores(
                  c['nome'],
                  c['consulta'],
                  c['indice'],
                  c['tipoResultado'])
                )
              });
            }
            Ind.formulaCalculoFarol = element['formulaCalculoFarol'];
            Ind.tempoAtualizacaoCalculoFarol = element['tempoAtualizacaoCalculoFarol'];
            Ind.temChegagemStatusPostoAgenda =element["temChegagemStatusPostoAgenda"]
            Ind.tipoFinal = element["tipoFinal"];
            Ind.consultasCalculoFarol = farolload;
          }
          if (Ind.etapa == 1) {
            this.listaIndEtapa1.push(Ind);
          }
          //console.log(Ind.camposConsulta)
          if (Ind.camposConsulta != undefined && Ind.camposConsulta.length > 0)
            this.listaIndAllEtapas.push(Ind);
            
          if (Ind.consultasCalculoFarol != undefined && Ind.consultasCalculoFarol.length > 0)
            this.listaIndAllEtapasComFarol.push(Ind);
        });
        this.listaIndEtapa1 = this.listaIndEtapa1.sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        });
        this.listaIndAllEtapas.sort((a, b) => {
          if (a.codigo === undefined && b.codigo === undefined) {
            if (a.etapa === undefined && b.etapa === undefined) { return 0; }
            if (a.etapa === undefined) { return -1; }
            if (b.etapa === undefined) { return 1; }
            return a.etapa - b.etapa;
          }
          if (a.codigo === undefined) { return -1; }
          if (b.codigo === undefined) { return 1; }
          if (a.codigo === b.codigo) {
            if (a.etapa === undefined && b.etapa === undefined) { return 0; }
            if (a.etapa === undefined) { return -1; }
            if (b.etapa === undefined) { return 1; }
            return a.etapa - b.etapa;
          }
          return a.codigo - b.codigo;
        });
        this.listaIndAllEtapasComFarol.sort((a, b) => {
          if (a.codigo === undefined && b.codigo === undefined) {
            if (a.etapa === undefined && b.etapa === undefined) { return 0; }
            if (a.etapa === undefined) { return -1; }
            if (b.etapa === undefined) { return 1; }
            return a.etapa - b.etapa;
          }
          if (a.codigo === undefined) { return -1; }
          if (b.codigo === undefined) { return 1; }
          if (a.codigo === b.codigo) {
            if (a.etapa === undefined && b.etapa === undefined) { return 0; }
            if (a.etapa === undefined) { return -1; }
            if (b.etapa === undefined) { return 1; }
            return a.etapa - b.etapa;
          }
          return a.codigo - b.codigo;
        });
        if (idEditar == undefined) {
          this.novoItem.codigo = this.obterMaiorCodigo() + 1;
          this.novoItem.etapa = 1;
        }


        if (idEditar != undefined)
          this.AbrirEditarItem(idEditar);

      });
    });

  }


  mudarMultiConsultaValor(novoValor: boolean) {
    this.EhMultiConsultaValor = novoValor;
  }

  mudarRegistroComCampos(novoValor: boolean) {
    this.EhRegistroComCampos = novoValor;
  }

  obterMaiorCodigo(): number {
    let maiorCodigo = -Infinity; // Inicializa com um valor muito baixo
    for (const objeto of this.listaIndEtapa1) {
      if (objeto.codigo != undefined)
        if (objeto.codigo > maiorCodigo) {
          maiorCodigo = objeto.codigo;
        }
    }
    console.log(maiorCodigo)
    return maiorCodigo;
  }
  alterarTravaEtapa(novoStatus: boolean) {
    if (novoStatus == false) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { title: 'Confirmação', message: 'Tem certeza que deseja editar a etapa do indicador? lembre-se que deverá fazer alterações para se adequar a nova etapa.' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.TravaEtapa = novoStatus;
        }
      });
    }
    else
      this.TravaEtapa = novoStatus;
  }

  AbrirEditarItem(id: string | null): void {
    console.log("CARREGADO", id);
    if (id != undefined) {
      this.TravaEtapa = true;

      this.indicadorService.editarItem(id?.toString()).subscribe(
        (response) => {
          let element = response["Indicador"];
          console.log("CARREGADO", element);
          console.log("HTML: ", element.textoAjudaHTML)
          this.novoItem = new indicador(
            element['id'],
            element['codigo'],
            element['etapa'],
            element['proximaEtapa'],
            element['tipoResultado'],
            element['nome'],
            element['descricao'],
            element['tempoAtualizacao'],
            element['icone'],
            element['consulta'],
            element['parametro'],
            element['colecao'],
            element['versaoIndice'],
            element['abreviatura'],
            element['formula'],
            undefined,
            element['formulaValor'],
            undefined,
            element['valoresPorcentagemCabecalho'],
            element['corDefault'],
            element['corDefaultDenominador'],
            element['corDefaultNominador'],
            element['corDefaultZero'],
            element['ordenacaoOrientacao'],
            element['linkRedirect'],
            element['nomeTipoTela'],
            undefined,
            element['visualizacaoDisplay'],
            element['ehGraficoIndicador'],
            element['tipoGraficoIndicador'],
            element['inativo'],
            element['ordemDashboard'],
            element['temRegraAberturaFechamento'],
            element["textoAjudaHTML"],
            element["temChegagemStatusPostoAgenda"]),
          this.consultasCor = [];
          this.consultasValor = [];
          this.data = element['calculoCores'];
          this.data.forEach(c => {
            if (c['nome'] == "Regra de Valor") {
              this.EhCalculoCorPadrao = 1;
            }
            if (c['nome'] == "Regra de Retorno da Formula") {
              this.EhCalculoCorPadrao = 2;
            }
            if (c['nome'] == "Regra de Valor") {
              this.EhCalculoCorPadrao = 3;
            }
            this.consultasCor.push(new CalculoCores(
              c['nome'],
              c['consulta'],
              c['indice'],
              c['tipoResultado'])
            )
          });

          if (this.novoItem.tipoResultado != undefined && this.listaTipoResultadoPorcentagem.includes(this.novoItem.tipoResultado))
            this.EhPorcentagem = true;
          else {
            this.EhPorcentagem = false;
            this.novoItem.valoresPorcentagemCabecalho = false;
          }

          this.data = element['multiplasConsultas'];

          this.data.forEach(c => {
            this.EhMultiConsultaValor = true;
            this.consultasValor.push(new CalculoCores(
              c['nome'],
              c['consulta'],
              c['indice'],
              c['tipoResultado'])
            )
          });

          this.data = element['consultasCalculoFarol'];
          if (this.data != null) {
            this.data.forEach(c => {
              this.consultasFarol.push(new CalculoCores(
                c['nome'],
                c['consulta'],
                c['indice'],
                c['tipoResultado'])
              )
            });
          }
          this.novoItem.formulaCalculoFarol = element['formulaCalculoFarol'];
          this.novoItem.tempoAtualizacaoCalculoFarol = element['tempoAtualizacaoCalculoFarol'];
          this.novoItem.tipoFinal = element["tipoFinal"];

          this.data = element['camposConsulta'];
          if (this.data != undefined) {
            this.data.forEach(c => {
              this.EhRegistroComCampos = true;
              this.campos.push(new Campos(
                c['nome'],
                c['campoCalculo'],
                c['tipoResultado'],
                c['ordem'],
                c['loopingPai'],
                c['icone'],
                c['naoMostraCampo'],
                c['naoMostraTitulo'],
                c['orientacaoLoop'],
                c['ehComponenteGrafico'],
                c['componenteGraficoHtml'],
                c['componenteGraficoCss'],
                c['componenteGraficoPossicao'],
                c['ehLink'],
                c['linkRedirect'],
                c['linkVariaveis'],
                c['ehCampoCabecalho'],
                c['tipoValorGrafico'],
                c['nomeConsultaRefentente'],
                c['ehOcultoPadrao'],
                c['ehCampoOrdenacao'],
              )
              )
            });
            this.campos.sort((a, b) => a.ordem - b.ordem);
          }
          if (this.novoItem.etapa != 1) {
            this.EhEtapa1 = false;
            console.log(this.novoItem);
            this.mySelectIndicador.nativeElement.value = this.novoItem.codigo;
          }
        },
        (error) => {
          console.error('Erro ao excluir o item:', error);
        }
      );
    }
  }

  mostrarCampoValorNocabecalho(): void {
    let NumeroEhPorcentagem: number | undefined = undefined;
    if (this.novoItem.tipoResultado != undefined)
      NumeroEhPorcentagem = this.listaTipoResultadoPorcentagem.find(x => x == this.novoItem.tipoResultado);
    if (NumeroEhPorcentagem != undefined)
      this.EhPorcentagem = true;
    else {
      this.EhPorcentagem = false;
      this.novoItem.valoresPorcentagemCabecalho = false;
    }
  }

  InativarItem(id: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Confirmação', message: 'Tem certeza que deseja inativar?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (id != undefined) {
          this.indicadorService.InativarItem(id?.toString()).subscribe(
            (response) => {
              this.novoItem.inativo = true;
              console.log('Item inativado com sucesso!');
            },
            (error) => {
              console.error('Erro ao inativar o item:', error);
            }
          );
        }
      }
    });

  }

  AtivarItem(id: string | null): void {
    if (id != undefined) {
      this.indicadorService.AtivarItem(id?.toString()).subscribe(
        (response) => {
          this.novoItem.inativo = false;
          console.log('Item Ativado com sucesso!');
        },
        (error) => {
          console.error('Erro ao ativar o item:', error);
        }
      );
    }
  }
}
<div style="display: flex;">

<div style="height: 34px; z-index: 9999; display: flex;align-items: baseline;">
  <mat-card-content>
    <mat-form-field class="example-form-field" [ngStyle]="{'width.px': width}">
      <mat-select [(value)]="PostosSelected" #matRef [multiple]="true" (selectionChange)="changeClient($event.value)">
       
       
       
       
        <div class="search-wrapper form-div p-3" style="width: 90%;">
          <form>
            <div style="border: 2px solid #d1d1d1;">
              <input #mySelectFiltro placeholder="Pesquisar..." matInput type="text" (keyup)="onKey(mySelectFiltro.value)" class="example-input">
                <button class="text-clear" type="reset" style="margin-left: 2px;" title="Clear" (click)="onKey(null)">
                  <i class="fas fa-times"></i>
                </button>
          
            </div>
          </form>
        </div>
        <mat-option (click)="toggleAllSelection()" [value]="'all'">
          <span [class.selected]="allSelected">Selecionar Todos</span>
        </mat-option>

        <mat-option *ngFor="let state of lista" [hidden]="isPostoDisabled(state)" [value]="state.codigoCentralizado">
          {{ state.descricao }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    
  </mat-card-content>
    <button title="Clear" class="text-clear" style="top: -6px;max-height: 37px;white-space: nowrap;" (click)="clear()"><i class="fas fa-times"></i></button>

</div>

</div>
export const environment ={
    env:'prod',
    api: "http://localhost:3000/sa_indicador_ponto_contratado_qtde",
    idpsp_issuer: 'https://idp.sp.gov.br/auth/realms/idpsp',
    idpsp_tokenEndpoint: 'https://idp.sp.gov.br/auth/realms/idpsp/protocol/openid-connect/token',
    idpsp_logoutUrl:'https://idp.sp.gov.br/auth/realms/idpsp/protocol/openid-connect/logout',
    well_known: 'https://idp.sp.gov.br/auth/realms/idpsp/.well-known/openid-configuration',
    API_URL: 'https://api.indicadores.sp.gov.br/sala-situacao-api/',
    redirectUri: '/',
    API_URL_MOTOR: 'http://motor.indicadores.sp.gov.br/',
}
